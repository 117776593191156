import React, { useEffect, useState } from 'react'
import { IListBillRequest, IParReportSearch } from '../service/interface'

interface IState {
  params: IListBillRequest
  parReportFilter: IParReportSearch
  setParReportFilter: React.Dispatch<React.SetStateAction<IParReportSearch>>
  setParams: React.Dispatch<React.SetStateAction<IListBillRequest>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setSortOrder: React.Dispatch<React.SetStateAction<string>>
}

const initialState: IState = {
  parReportFilter: {
    client: null,
    entity: null,
  },
  params: {
    page_size: 10,
  },
  setParams: () => null,
  setParReportFilter: () => null,
  setPage: () => null,
  setSortOrder: () => null,
}

export const ParReportParamsContext = React.createContext<IState>(initialState)

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const ParReportParamsContextProvider: React.FC<Props> = ({ children }) => {
  const [parReportFilter, setParReportFilter] = useState<IParReportSearch>(
    initialState.parReportFilter
  )
  const [params, setParams] = useState<IListBillRequest>({
    page_size: 10,
  })
  const [page, setPage] = useState<number>(1)
  const [sortOrder, setSortOrder] = React.useState<string>('')

  useEffect(() => {
    setParams((prev) => ({ ...prev, page }))
  }, [page])

  useEffect(() => {
    setParams((prev) => ({ ...prev, ordering: sortOrder }))
  }, [sortOrder])

  return (
    <ParReportParamsContext.Provider
      value={{
        params,
        parReportFilter,
        setParReportFilter,
        setParams,
        setPage,
        setSortOrder,
      }}
    >
      {children}
    </ParReportParamsContext.Provider>
  )
}
