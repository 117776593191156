import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query'

import {
  clientBillMessageSummary,
  listAccountClients,
  listClientGlobalMessages,
  listClientBills,
  partialEntityUpdate,
  retrieveClientBill,
  updateRejectClientBillAction,
  updateApproveClientBillAction,
  listClientBillMessages,
  createClientBillMessage,
  markClientBillMessageAsRead,
  clientBillSummaryMetrics,
  createEntity,
  updateBulkApproveClientBillAction,
  getClientBillsByPaidDate,
  listClientReportsSummary,
  listAccountAssignedClients,
  listClientEntitiesVendors,
  listClientEntitiesDivisions,
  listClientEntitiesAccounts,
  updateClientBillRevise,
} from '../api'
import {
  IBill,
  IBillMessageReadRequest,
  IBillMessageSummaryResponse,
  IClientBillActionBulkApproveRequest,
  IBillsByPaidDateResponse,
  IClientBillActionRequest,
  ICreateBillMessageRequest,
  ICreateEntityRequest,
  IError,
  IListBillMessageResponse,
  IListBillRequest,
  IListBillSummaryResponse,
  IListClientsResponse,
  IUpdateEntityRequest,
  IReportSummaryResponse,
  IListAssignedClientsResponse,
  IListVendors,
  IListBills,
  IListVendorRequest,
  IEntity,
  IOrgBillReviseRequest,
  IReportSummaryRequest,
} from '../interface'

export const ClientBillQueryKeys = {
  GET_BILL_LIST: 'GET_CLIENT_BILL_LIST',
  GET_CLIENT_LIST: 'GET_CLIENT_LIST',
  GET_BILL: 'GET_CLIENT_BILL',
  GET_ASSIGNED_CLIENT_LIST: 'GET_ASSIGNED_CLIENT_LIST',
  GET_BILL_MESSAGE_SUMMARY: 'GET_CLIENT_BILL_MESSAGE_SUMMARY',
  GET_GLOBAL_MESSAGE_LIST: 'GET_CLIENT_GLOBAL_MESSAGE_LIST',
  GET_BILL_MESSAGE_LIST: 'GET_CLIENT_BILL_MESSAGE_LIST',
  GET_BILL_SUMMARY_METRICS: 'GET_CLIENT_BILL_SUMMARY_METRICS',
  GET_BILLS_BY_PAID_DATE: 'GET_BILLS_BY_PAID_DATE',
  GET_ENTITY_LIST_SUMMARY: 'GET_ENTITY_LIST_SUMMARY',
  GET_VENDOR_LIST: 'GET_CLIENT_VENDOR_LIST',
  GET_DIVISION_LIST: 'GET_CLIENT_DIVISION_LIST',
  GET_ACCOUNT_LIST: 'GET_CLIENT_ACCOUNT_LIST',
}

export const useClientListBills = (
  clientGuid: string,
  payload: IListBillRequest,
  config?: UseQueryOptions<IListBills, IError, IListBills>
) =>
  useQuery(
    [ClientBillQueryKeys.GET_BILL_LIST, clientGuid, payload],
    () => listClientBills(clientGuid, payload),
    config
  )

export const useRetrieveClientBill = (
  clientGuid: string,
  billGuid: string,
  config?: UseQueryOptions<IBill, unknown, IBill>
) =>
  useQuery(
    [ClientBillQueryKeys.GET_BILL, { clientGuid, billGuid }],
    () => retrieveClientBill(clientGuid, billGuid),
    config
  )

export const useListClients = (
  page: number,
  config?: UseQueryOptions<IListClientsResponse, IError, IListClientsResponse>
) =>
  useQuery([ClientBillQueryKeys.GET_CLIENT_LIST, page], () => listAccountClients({ page }), config)

export const useListAssignedClients = (
  page: number,
  config?: UseQueryOptions<IListAssignedClientsResponse, IError, IListAssignedClientsResponse>
) =>
  useQuery(
    [ClientBillQueryKeys.GET_ASSIGNED_CLIENT_LIST, page],
    () => listAccountAssignedClients({ page }),
    config
  )

export const useCreateEntity = (
  config?: UseMutationOptions<
    IEntity,
    IError,
    { clientGuid: string; payload: ICreateEntityRequest }
  >
) =>
  useMutation<IEntity, IError, { clientGuid: string; payload: ICreateEntityRequest }>(
    createEntity,
    config
  )

export const useUpdateEntity = (
  config?: UseMutationOptions<
    IEntity,
    IError,
    { clientGuid: string; entityGuid: string; payload: IUpdateEntityRequest }
  >
) =>
  useMutation<
    IEntity,
    IError,
    { clientGuid: string; entityGuid: string; payload: IUpdateEntityRequest }
  >(partialEntityUpdate, config)

export const useClientBillActionApproveUpdate = () =>
  useMutation<undefined, IError, IClientBillActionRequest>(updateApproveClientBillAction)

export const useClientBillActionBulkApproveUpdate = () =>
  useMutation<undefined, IError, IClientBillActionBulkApproveRequest>(
    updateBulkApproveClientBillAction
  )

export const useClientBillActionRejectUpdate = () =>
  useMutation<undefined, IError, IClientBillActionRequest>(updateRejectClientBillAction)

export const useClientGlobalBillMessageSummary = (clientGuid: string) =>
  useQuery<IBillMessageSummaryResponse, IError>(
    [ClientBillQueryKeys.GET_BILL_MESSAGE_SUMMARY, clientGuid],
    () => clientBillMessageSummary(clientGuid)
  )

export const useClientGlobalMessageList = (clientGuid: string) =>
  useQuery<IListBillMessageResponse, IError>(
    [ClientBillQueryKeys.GET_GLOBAL_MESSAGE_LIST, clientGuid],
    () => listClientGlobalMessages(clientGuid)
  )

export const useClientBillMessageList = (clientGuid: string, billGuid: string, page?: number) =>
  useQuery<IListBillMessageResponse, IError>(
    [ClientBillQueryKeys.GET_BILL_MESSAGE_LIST, { clientGuid, billGuid, page }],
    () => listClientBillMessages(clientGuid, billGuid, page)
  )

export const useCreateClientBillMessage = () =>
  useMutation<undefined, IError, ICreateBillMessageRequest>(createClientBillMessage)

export const useMarkClientBillMessageAsRead = () =>
  useMutation<undefined, IError, IBillMessageReadRequest>(markClientBillMessageAsRead)

export const useClientBillSummaryMetrics = (clientGuid: string, payload: IListBillRequest) =>
  useQuery<IListBillSummaryResponse, IError>(
    [ClientBillQueryKeys.GET_BILL_SUMMARY_METRICS, { clientGuid, payload }],
    () => clientBillSummaryMetrics(clientGuid, payload)
  )

export const useClientBillsByPaidDate = (clientGuid: string) =>
  useQuery<IBillsByPaidDateResponse, IError>(
    [ClientBillQueryKeys.GET_BILLS_BY_PAID_DATE, clientGuid],
    () => getClientBillsByPaidDate(clientGuid)
  )

export const useClientReportsSummary = (clientGuid: string, payload: IReportSummaryRequest) =>
  useQuery<IReportSummaryResponse, IError>(
    [ClientBillQueryKeys.GET_ENTITY_LIST_SUMMARY, clientGuid, payload],
    () => listClientReportsSummary(clientGuid, payload)
  )

export const useClientEntityVendorList = (
  clientGuid: string,
  entityGuid: string,
  params: IListVendorRequest
) =>
  useQuery<IListVendors, IError>(
    [ClientBillQueryKeys.GET_VENDOR_LIST, clientGuid, entityGuid, params],
    () => listClientEntitiesVendors(clientGuid, entityGuid, params),
    { enabled: !!entityGuid }
  )

export const useClientEntityDivisionList = (
  clientGuid: string,
  entityGuid: string,
  page: number,
  page_size: number,
  archived?: boolean,
  search?: string
) =>
  useQuery(
    [ClientBillQueryKeys.GET_DIVISION_LIST, clientGuid, entityGuid, page, page_size, search],
    () => listClientEntitiesDivisions(clientGuid, entityGuid, page, page_size, search, archived),
    {
      enabled: !!entityGuid,
    }
  )

export const useClientListAccounts = (
  clientGuid: string,
  entityGuid: string,
  page: number,
  pageSize: number,
  archived?: boolean,
  search?: string
) =>
  useQuery(
    [ClientBillQueryKeys.GET_ACCOUNT_LIST, clientGuid, entityGuid, page, search],
    () => listClientEntitiesAccounts(clientGuid, entityGuid, page, pageSize, archived, search),
    {
      enabled: !!entityGuid,
    }
  )

export const useClientBillActionRevise = () =>
  useMutation<undefined, IError, IOrgBillReviseRequest>(updateClientBillRevise)
