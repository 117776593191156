import jwt_decode from 'jwt-decode'
import { createTokenRefresh, blacklistTokens, renewPasswordClaim } from '../service/api'

import { IDecodedToken, INormalLoginResponse } from '../service/interface'

import { clientGuidProperty } from './client'
import { orgGuidProperty, orgNameProperty } from './organization'

export const accessTokenProperty = 'access_token'
export const refreshTokenProperty = 'refresh_token'
export const maskAccessTokenProperty = 'mask_access_token'
export const maskRefreshTokenProperty = 'mask_refresh_token'
export const rememberMeProperty = 'remember_me_value'
export const userEmailProperty = 'user_email_property'
export const redirectUrlProperty = 'redirect_url'

export const getAccessToken = () => window.localStorage.getItem(accessTokenProperty) || ''
export const getRefreshToken = () => window.localStorage.getItem(refreshTokenProperty) || ''
export const getMaskAccessToken = () => window.localStorage.getItem(maskAccessTokenProperty) || ''
export const getMaskRefreshToken = () => window.localStorage.getItem(maskRefreshTokenProperty) || ''

export const getCookie = (name: string) => {
  const re = new RegExp(name + '=([^;]+)')
  const value = re.exec(document.cookie)
  return value != null ? unescape(value[1]) : null
}

export const getUserEmail = () => getCookie(userEmailProperty) || ''
export const getRememberMe = () => getCookie(rememberMeProperty) === 'true'

export const isTokenExpired = (expUnixTimestamp: number) => {
  const expDate = new Date(expUnixTimestamp * 1000)
  return expDate.getTime() - Date.now() <= 0
}

export const performValidation = async (): Promise<boolean> => {
  const accessToken = getAccessToken()
  const refreshToken = getRefreshToken()

  if (!accessToken) return false

  try {
    const { exp: accessExp } = jwt_decode<IDecodedToken>(accessToken)
    if (isTokenExpired(accessExp)) {
      if (!refreshToken) return false

      const { exp: refreshExp } = jwt_decode<IDecodedToken>(refreshToken)

      if (isTokenExpired(refreshExp)) {
        return false
      } else {
        const data = await createTokenRefresh(refreshToken)
        if (!!data && !!data.access) {
          window.localStorage.setItem(accessTokenProperty, data.access)
          return true
        } else {
          return false
        }
      }
    }
    return true
  } catch (e) {
    return false
  }
}

export const getValidAccessToken = async (): Promise<string> => {
  let accessToken = getAccessToken()
  const refreshToken = getRefreshToken()

  if (!accessToken && !refreshToken) {
    throw new Error('No tokens available')
  }

  try {
    const { exp: accessExp } = jwt_decode<IDecodedToken>(accessToken)

    if (isTokenExpired(accessExp)) {
      if (!refreshToken) {
        throw new Error('No refresh token available')
      }

      const { exp: refreshExp } = jwt_decode<IDecodedToken>(refreshToken)

      if (isTokenExpired(refreshExp)) {
        throw new Error('Refresh token is expired')
      } else {
        const data = await createTokenRefresh(refreshToken)
        if (!!data && !!data.access) {
          accessToken = data.access
          window.localStorage.setItem(accessTokenProperty, accessToken)
        } else {
          throw new Error('Failed to refresh access token')
        }
      }
    }

    return accessToken
  } catch (e: any) {
    throw new Error(`Token validation error: ${e.message}`)
  }
}

export const isUserStaffAdminOrMember = (): boolean => {
  try {
    const accessToken = window.localStorage.getItem(accessTokenProperty) || ''
    const { staff } = jwt_decode<IDecodedToken>(accessToken)
    return staff === 'admin' || staff === 'member'
  } catch (e) {
    return false
  }
}

export const saveGeneralAuthToStorage = (
  data: INormalLoginResponse,
  rememberMe = false,
  userEmail = ''
) => {
  window.localStorage.setItem(accessTokenProperty, data.access)
  window.localStorage.setItem(refreshTokenProperty, data.refresh)

  document.cookie = `${rememberMeProperty}=${String(rememberMe)}`
  if (rememberMe) document.cookie = `${userEmailProperty}=${userEmail}`
}

export const saveMaskAuthToStorage = (data: INormalLoginResponse) => {
  window.localStorage.setItem(maskAccessTokenProperty, getAccessToken())
  window.localStorage.setItem(maskRefreshTokenProperty, getRefreshToken())
  saveGeneralAuthToStorage(data)
}

export const clearMaskAuthStorage = () => {
  blacklistTokens(getRefreshToken(), getAccessToken())
  window.localStorage.setItem(accessTokenProperty, getMaskAccessToken())
  window.localStorage.setItem(refreshTokenProperty, getMaskRefreshToken())
  window.localStorage.removeItem(maskAccessTokenProperty)
  window.localStorage.removeItem(maskRefreshTokenProperty)
  window.localStorage.removeItem(clientGuidProperty)
  window.localStorage.removeItem(orgGuidProperty)
  window.localStorage.removeItem(orgNameProperty)
}

export const logout = () => {
  blacklistTokens(getMaskRefreshToken(), getMaskAccessToken())
  blacklistTokens(getRefreshToken(), getAccessToken())
  window.localStorage.clear()
  window.location.href = '/'
}

export const isBpOrgUser = (): boolean | null => {
  const accessToken = getAccessToken()
  let decoded: IDecodedToken | undefined
  try {
    decoded = jwt_decode<IDecodedToken>(accessToken)
  } finally {
    if (decoded) {
      if (decoded.type === 'bporg') {
        return true
      } else return false
    } else {
      return null
    }
  }
}

export const isPasswordExpired = (): boolean | null => {
  const accessToken = getAccessToken()
  let decoded: IDecodedToken | undefined
  try {
    decoded = jwt_decode<IDecodedToken>(accessToken)
  } finally {
    if (decoded) {
      if (decoded.password_expired) {
        return true
      } else return false
    } else {
      return null
    }
  }
}

export const renewTokenClaims = async () => {
  const data = await renewPasswordClaim(getRefreshToken())
  window.localStorage.setItem(accessTokenProperty, data.access)
  window.localStorage.setItem(refreshTokenProperty, data.refresh)
}

export const getRedirectUrl = () => window.localStorage.getItem(redirectUrlProperty)
export const setRedirectUrl = (val: string) => window.localStorage.setItem(redirectUrlProperty, val)
export const clearRedirectUrl = () => window.localStorage.removeItem(redirectUrlProperty)
