import React, { useContext, useEffect } from 'react'
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom'
import { use2FACheck, useAuthentication } from '../../service/hooks/auth'
import { FeatureFlaggingContext } from '../../contexts/FeatureFlaggingContext'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...restProps }: RouteProps) => {
  const { loading, isAuthenticated } = useAuthentication()
  const shouldEnable2FA = use2FACheck(isAuthenticated)
  const { isVendorManagementEnabled, isLoadingUserFeatures } = useContext(FeatureFlaggingContext)
  const history = useHistory()

  useEffect(() => {
    if (restProps.path?.includes('/parent-vendors')) {
      if (isVendorManagementEnabled === false) {
        history.push('/manager-dashboard')
      }
    }
  }, [history, isVendorManagementEnabled, restProps.path])

  if (loading || isLoadingUserFeatures) {
    return <></>
  }

  // return isAuthenticated && !shouldEnable2FA ? <Route {...restProps} /> : <Redirect to="/login" />
  return (
    <Route
      {...restProps}
      render={({ location }) =>
        isAuthenticated && !shouldEnable2FA ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
