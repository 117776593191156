import React from 'react'
import { Context, createContext, Dispatch, useReducer } from 'react'
import footerReducer, { FooterActions, FooterState } from './footerReducer'
import headerReducer, { HeaderActions, HeaderState } from './headerReducer'
import mainContentReducer, { MainContentActions, MainContentState } from './mainContentReducer'
import pageTitleReducer, { PageTitleAction, PageTitleState } from './pageTitleReducer'
import sidebarReducer, { SidebarActions, SidebarState, SidebarType } from './sidebarReducer'

interface State {
  header: HeaderState
  footer: FooterState
  mainContent: MainContentState
  pageTitle: PageTitleState
  sidebar: SidebarState
}

const initialState: State = {
  header: {
    headerFixed: true,
    headerShadow: true,
    headerBgTransparent: true,
    headerSearchHover: false,
    headerDrawerToggle: false,
  },
  footer: {
    footerFixed: false,
    footerShadow: false,
    footerBgTransparent: true,
  },
  mainContent: {
    contentBackground: '',
    themeConfiguratorToggle: false,
  },
  pageTitle: {
    pageTitleStyle: '',
    pageTitleBackground: '',
    pageTitleShadow: false,
    pageTitleIconBox: true,
    pageTitleDescription: true,
  },
  sidebar: {
    sidebarFixed: true,
    sidebarFooter: true,
    sidebarShadow: false,
    sidebarStyle: 'app-sidebar--dark',
    sidebarUserbox: true,
    sidebarToggleMobile: false,
    sidebarToggle: false,
    sidebarType: SidebarType.NAVIGATION,
  },
}

const ThemeOptionsContext: Context<{
  state: State
  dispatch: Dispatch<
    HeaderActions | FooterActions | MainContentActions | PageTitleAction | SidebarActions
  >
}> = createContext<{
  state: State
  dispatch: Dispatch<
    HeaderActions | FooterActions | MainContentActions | PageTitleAction | SidebarActions
  >
}>({
  state: initialState,
  dispatch: () => null,
})

const mainReducer = (
  { header, footer, sidebar, pageTitle, mainContent }: State,
  action: HeaderActions | FooterActions | MainContentActions | PageTitleAction | SidebarActions
) => ({
  header: headerReducer(header, action as HeaderActions),
  footer: footerReducer(footer, action as FooterActions),
  sidebar: sidebarReducer(sidebar, action as SidebarActions),
  pageTitle: pageTitleReducer(pageTitle, action as PageTitleAction),
  mainContent: mainContentReducer(mainContent, action as MainContentActions),
})

const ThemeOptionsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)

  return (
    <ThemeOptionsContext.Provider value={{ state, dispatch }}>
      {children}
    </ThemeOptionsContext.Provider>
  )
}

export { ThemeOptionsContext, ThemeOptionsProvider }
