import { DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'

export interface DialogTitleProps {
  children?: React.ReactNode
  onClose?: () => void
}

export const DialogTitleWithClose = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
