import { Box, Link, Typography } from '@mui/material'
import OtpInput from 'react-otp-input'
import React from 'react'

interface Props {
  value: string
  onChange: (otp: string) => void
  hasErrored: boolean
  onResend: () => void
  isResendLoading: boolean
}

const CustomOTPInput: React.FC<Props> = ({
  hasErrored,
  onChange,
  value,
  onResend,
  isResendLoading,
}) => {
  return (
    <Box display="inline-flex" flexDirection="column">
      <OtpInput
        value={value}
        onChange={onChange}
        numInputs={6}
        isInputNum
        shouldAutoFocus
        hasErrored={hasErrored}
        containerStyle={{
          width: '450px',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
        inputStyle={{
          width: '64px',
          height: '75px',
          background: '#FAFAFA',
          border: '1px solid #EEEEEE',
          borderRadius: '4px',
          fontSize: '32px',
        }}
        errorStyle={{
          border: '1px solid red',
        }}
      />
      <Box display="flex" mb={12.5} justifyContent="flex-end">
        <Typography mr={2} fontSize={14} fontWeight={325}>
          Didn't receive code?
        </Typography>
        <Link component="button" type="button" fontSize={14} fontWeight={350} onClick={onResend}>
          {isResendLoading ? 'Resending...' : 'Resend'}
        </Link>
      </Box>
    </Box>
  )
}

export default CustomOTPInput
