import React, { useCallback, useEffect, useState } from 'react'
import { IDateRange, IListBillRequest } from '../service/interface'
import { defaultDateRange } from './AdvancedSearchContext'

interface IState {
  params: IListBillRequest
  selectedDateRange: IDateRange
  selectedPaidDateRange: IDateRange
  setParams: React.Dispatch<React.SetStateAction<IListBillRequest>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setSortOrder: React.Dispatch<React.SetStateAction<string>>
  setSelectedDateRange: React.Dispatch<React.SetStateAction<IDateRange>>
  setSelectedPaidDateRange: React.Dispatch<React.SetStateAction<IDateRange>>
  setSelectedEntity: (entities: string) => void
  setSelectedClient: (clients: string) => void
  setFrequency: (frequency: string) => void
}

const initialState: IState = {
  params: {
    page_size: 10,
  },
  selectedDateRange: defaultDateRange,
  selectedPaidDateRange: defaultDateRange,
  setParams: () => null,
  setPage: () => null,
  setSortOrder: () => null,
  setSelectedDateRange: () => null,
  setSelectedPaidDateRange: () => null,
  setSelectedEntity: () => {
    return
  },
  setSelectedClient: () => {
    return
  },
  setFrequency: () => {
    return
  },
}

export const BillParamsContext = React.createContext<IState>(initialState)

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const BillParamsContextProvider: React.FC<Props> = ({ children }) => {
  const [params, setParams] = useState<IListBillRequest>({
    page_size: 10,
  })
  const [page, setPage] = useState<number>(1)
  const [sortOrder, setSortOrder] = React.useState<string>('')
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>(defaultDateRange)
  const [selectedPaidDateRange, setSelectedPaidDateRange] = useState<IDateRange>(defaultDateRange)

  const setSelectedEntity = useCallback(
    (entities: string) =>
      setParams((prev) => ({
        ...prev,
        entities: entities === 'all-entities' ? undefined : entities,
      })),
    [setParams]
  )
  const setSelectedClient = useCallback(
    (clients: string) =>
      setParams((prev) => ({ ...prev, clients: clients === 'all-clients' ? undefined : clients })),
    [setParams]
  )
  const setFrequency = useCallback(
    (frequency: string) =>
      setParams((prev) => ({
        ...prev,
        frequency: frequency ? frequency : undefined,
      })),
    [setParams]
  )

  useEffect(() => {
    setParams((prev) => ({ ...prev, page }))
  }, [page])

  useEffect(() => {
    setParams((prev) => ({ ...prev, ordering: sortOrder }))
  }, [sortOrder])

  return (
    <BillParamsContext.Provider
      value={{
        params,
        selectedDateRange,
        selectedPaidDateRange,
        setParams,
        setPage,
        setSortOrder,
        setSelectedDateRange,
        setSelectedPaidDateRange,
        setSelectedEntity,
        setSelectedClient,
        setFrequency,
      }}
    >
      {children}
    </BillParamsContext.Provider>
  )
}
