import React, { useEffect, useState } from 'react'
import { IDateRange, IAuditHistorySearch, IAuditHistoryRequest } from '../service/interface'
import { defaultDateRange } from './AdvancedSearchContext'

export interface IActionType {
  value: string
  title: string
}

interface IState {
  params: IAuditHistoryRequest
  selectedDateRange: IDateRange
  auditInputFilter: IAuditHistorySearch
  setAuditInputFilter: React.Dispatch<React.SetStateAction<IAuditHistorySearch>>
  setParams: React.Dispatch<React.SetStateAction<IAuditHistoryRequest>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setSortOrder: React.Dispatch<React.SetStateAction<string>>
  setSelectedDateRange: React.Dispatch<React.SetStateAction<IDateRange>>
}

const initialState: IState = {
  auditInputFilter: {
    client: null,
    user: null,
    actionType: '',
    selectedDateRange: defaultDateRange,
    startDate: 'Start Date',
    endDate: 'End Date',
  },
  params: {
    page_size: 10,
  },
  selectedDateRange: defaultDateRange,
  setParams: () => null,
  setAuditInputFilter: () => null,
  setPage: () => null,
  setSortOrder: () => null,
  setSelectedDateRange: () => null,
}

export const AuditHistoryContext = React.createContext<IState>(initialState)

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const AuditHistoryContextProvider: React.FC<Props> = ({ children }) => {
  const [auditInputFilter, setAuditInputFilter] = useState<IAuditHistorySearch>(
    initialState.auditInputFilter
  )
  const [params, setParams] = useState<IAuditHistoryRequest>({
    page_size: 10,
  })
  const [page, setPage] = useState<number>(1)
  const [sortOrder, setSortOrder] = React.useState<string>('')
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>(defaultDateRange)

  useEffect(() => {
    setParams((prev) => ({ ...prev, page }))
  }, [page])

  useEffect(() => {
    setParams((prev) => ({ ...prev, ordering: sortOrder }))
  }, [sortOrder])

  return (
    <AuditHistoryContext.Provider
      value={{
        params,
        selectedDateRange,
        auditInputFilter,
        setAuditInputFilter,
        setParams,
        setPage,
        setSortOrder,
        setSelectedDateRange,
      }}
    >
      {children}
    </AuditHistoryContext.Provider>
  )
}
