import { createTheme } from '@mui/material'

import GothamLight from '../assets/fonts/Gotham/Gotham-Light.otf'
import GothamBook from '../assets/fonts/Gotham/Gotham-Book.otf'
import GothamMedium from '../assets/fonts/Gotham/Gotham-Medium.otf'
import GothamBold from '../assets/fonts/Gotham/Gotham-Bold.otf'

const theme = createTheme({
  palette: {
    primary: {
      main: '#2B388D',
    },
    grey: {
      300: '#fefefe',
      A100: '#f8f9ff',
    },
    secondary: {
      main: '#4191ff',
    },
    error: {
      main: '#f83245',
      light: '#EF5350',
    },
    success: {
      main: '#2E7D32',
      light: '#4CAF50',
    },
    info: {
      main: '#0288D1',
    },
    warning: {
      main: '#f4772e',
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1100,
      xl: 1381,
    },
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        autoHighlight: true,
        autoSelect: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            py: 1.5,
            textTransform: 'none',
            fontWeight: 350,
            height: '40px',
          }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 350,
          borderRadius: 10,
          height: '30px',
        },
        label: {
          padding: '4px 9px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-family: 'Gotham';
            font-weight: 300;
            src: url(${GothamLight}) format('opentype');
        }

        @font-face {
            font-family: 'Gotham';
            font-weight: 325;
            src: url(${GothamBook}) format('opentype');
        }

        @font-face {
            font-family: 'Gotham';
            font-weight: 350;
            src: url(${GothamMedium}) format('opentype');
        }

        @font-face {
            font-family: 'Gotham';
            font-weight: 400;
            src: url(${GothamBold}) format('opentype');
        }
      `,
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => theme.unstable_sx({ py: 2.5 }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            m: 0,
            p: 2,
            fontSize: '16px',
          }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        // Disable showing asterisk beside labels of required inputs.
        required: false,
        // This shows the placeholders in the TextField
        shrink: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 350,
          fontSize: 14,
          marginBottom: 4,
          color: '#394A64',
        },
        formControl: {
          // Disable Material-based label style (placed inside of input, then shrinks to top border)
          position: 'relative',
          transform: 'none',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        fontSize: 14,
        fontWeight: 350,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          borderRadius: '4px',
          height: '40px',
        },
        multiline: {
          height: 'auto',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {},
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#070919',
          padding: '8px 16px',
          fontSize: '13px',
        },
        arrow: {
          color: '#070919',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          width: '30px',
          height: '30px',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Gotham', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 325,
    fontWeightMedium: 350,
    fontWeightBold: 400,
  },
  shadows: [
    'none',
    '0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
    '0 0.313rem 0.8rem rgba(122, 123, 151, 0.5), 0 0.126rem 0.225rem rgba(122, 123, 151, 0.3)',
  ],
})

export default theme
