import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { use2FACheck, useAuthentication } from '../../service/hooks/auth'
import { clearRedirectUrl, getRedirectUrl, isBpOrgUser, isPasswordExpired } from '../../utils/auth'

const PublicRoute: React.FC<RouteProps> = ({ ...restProps }: RouteProps) => {
  const { loading, isAuthenticated } = useAuthentication()
  const shouldEnable2FA = use2FACheck(isAuthenticated)

  if (loading) return <></>

  const isOrgUser = isBpOrgUser()
  const passwordExpired = isPasswordExpired()

  const redirectUrl = getRedirectUrl()

  if (isAuthenticated && !shouldEnable2FA) {
    if (passwordExpired) {
      return <Redirect to="/user/reset-password-expiry" />
    }
    if (redirectUrl) {
      clearRedirectUrl()
      return <Redirect to={redirectUrl} />
    }
    if (isOrgUser) {
      return <Redirect to="/manager-dashboard" />
    } else {
      return <Redirect to="/dashboard" />
    }
  }
  return <Route {...restProps} />
}

export default PublicRoute
