import React, { useContext, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { EToastifyType, ToastifyContext } from '../../../../contexts/ToastifyContext'
import {
  AuthQueryKeys,
  use2FAActivate,
  use2FAConfirm,
  useChange2FAMethod,
  useRequest2FACode,
} from '../../../../service/hooks/auth'
import { getRefreshToken, saveGeneralAuthToStorage } from '../../../../utils/auth'
import { INormalLoginResponse } from '../../../../service/interface'
import { Button, Stack, Typography } from '@mui/material'
import { TwoFASetupStepProps } from './interface'
import CustomDialog from '../../../../components/CustomDialog'
import CustomOTPInput from '../../../../components/CustomOTPInput'
import { E2FAMethod } from '../../../../service/enum'
import { getErrorText } from '../../../../components/ErrorAlert'

const VerifyCodeStep: React.FC<TwoFASetupStepProps> = ({
  open,
  onClose,
  onBack,
  onNext,
  faValue,
  faMethod,
}) => {
  const queryClient = useQueryClient()
  const { showToastify } = useContext(ToastifyContext)
  const successMsg = useMemo(() => {
    if (faMethod === E2FAMethod.sms) {
      return 'Successfully verified the phone number and added 2FA'
    } else {
      return 'Successfully verified email and added 2FA'
    }
  }, [faMethod])
  const headerLabel = useMemo(() => {
    if (faMethod === E2FAMethod.sms) {
      return 'Please enter the code sent to your phone'
    } else {
      return 'Please enter the code sent to your email address'
    }
  }, [faMethod])
  const { mutateAsync: request2FAConfirm, isError, isLoading } = use2FAConfirm({
    onSuccess: (data) => {
      showToastify({
        type: EToastifyType.success,
        message: successMsg,
      })
      saveGeneralAuthToStorage(data as INormalLoginResponse)
      onNext()
    },
  })
  const [otp, setOtp] = useState('')
  const { mutateAsync: requestActivate, isLoading: isResendLoading } = use2FAActivate()
  const {
    mutateAsync: requestChange2FAMethod,
    isError: isChangeError,
    isLoading: isChangeLoading,
  } = useChange2FAMethod()
  const { mutateAsync: request2FACode } = useRequest2FACode()

  const handleResend = async () => {
    try {
      await requestActivate({
        value: faValue,
        method: faMethod,
      })
    } catch (err) {
      if (err?.response?.data?.error === 'MFA method already active.') {
        request2FACode({ method: faMethod })
      }
    }
  }

  const handleConfirm = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      debugger
      await request2FAConfirm({
        code: otp,
        method: faMethod,
        refresh: getRefreshToken(),
      })
      await requestChange2FAMethod({ method: faMethod, code: otp })
    } catch (e) {
      if (e?.response?.data?.code?.includes('MFA method already active.')) {
        await requestChange2FAMethod({ method: faMethod, code: otp })
        showToastify({
          type: EToastifyType.success,
          message: 'MFA method updated.',
        })
      } else {
        showToastify({
          type: EToastifyType.error,
          header: 'Error occurred.',
          message: `${getErrorText(e)}`,
        })
      }
    }
    onNext()
    await queryClient.refetchQueries([AuthQueryKeys.GET_USER, 'me'])
  }

  return (
    <CustomDialog
      open={open}
      title="Two Factor Authentication Setup"
      onClose={onClose}
      buttonCloseOnly
      contentDividers
      formOnSubmit={handleConfirm}
      actions={
        <>
          <Button variant="outlined" onClick={onBack}>
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={handleConfirm}
            disabled={isLoading || isChangeLoading}
          >
            Confirm
          </Button>
        </>
      }
    >
      <Stack alignItems="center">
        <Typography mb={2} fontSize={16} fontWeight={350}>
          {headerLabel}
        </Typography>
        <CustomOTPInput
          value={otp}
          onChange={setOtp}
          hasErrored={isError && isChangeError}
          onResend={handleResend}
          isResendLoading={isResendLoading}
        />
      </Stack>
    </CustomDialog>
  )
}

export default VerifyCodeStep
