import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Divider } from '@mui/material'

interface DrawerProps {
  content: React.ReactNode | React.ReactNode[]
  header: React.ReactNode | React.ReactNode[]
  _anchor?: 'left' | 'top' | 'right' | 'bottom'
}

interface IState {
  showDrawer: (payload: DrawerProps) => void
  handleClose: () => void
}

const initialState: IState = {
  showDrawer: () => null,
  handleClose: () => null,
}

export const SidebarContext = React.createContext<IState>(initialState)

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const SidebarContextProvider: React.FC<Props> = ({ children }) => {
  const [anchor, setAnchor] = useState<'left' | 'top' | 'right' | 'bottom'>('right')
  const [open, setOpen] = useState<boolean>(false)
  const [drawerContent, setDrawerContent] = useState<React.ReactNode | React.ReactNode[]>('')
  const [drawerHeader, setDrawerHeader] = useState<React.ReactNode | React.ReactNode[]>('')

  const showDrawer = ({ content, header, _anchor = 'right' }: DrawerProps) => {
    setDrawerContent(content)
    setAnchor(_anchor)
    setDrawerHeader(header)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setDrawerContent('')
  }

  return (
    <SidebarContext.Provider value={{ showDrawer, handleClose }}>
      {children}
      <Drawer anchor={anchor} open={open} onClose={handleClose}>
        <Box
          maxWidth="400px"
          minWidth="300px"
          padding="20px"
          margin="env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {drawerHeader}
            <IconButton aria-label="close" onClick={handleClose} size="large" sx={{ p: '5px' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider style={{ marginTop: '10px' }} />
          {drawerContent}
        </Box>
      </Drawer>
    </SidebarContext.Provider>
  )
}
