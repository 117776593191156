import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import projectLogo from '../../../assets/images/all_white_logo.png'
import minimizedLogo from '../../../assets/images/white_minimized_logo.png'
import clsx from 'clsx'
import {
  StyledAppSideBarCollapsed,
  StyledAppSideBarExpanded,
  StyledAppSideBarHeader,
} from '../../../utils/styles'

const SidebarHeader: React.FC = () => {
  const location = useLocation()
  const isDashboardActive =
    location.pathname === '/manager-dashboard' || location.pathname === '/dashboard' ? true : false

  return (
    <>
      <StyledAppSideBarHeader className={clsx({ active: isDashboardActive })}>
        <StyledAppSideBarCollapsed>
          <NavLink to="/" title="Plumb Bill Pay" className="app-sidebar-logo">
            <img alt="Billpay Logo" src={minimizedLogo} />
          </NavLink>
        </StyledAppSideBarCollapsed>
        <StyledAppSideBarExpanded>
          <NavLink to="/" title="Plumb Bill Pay" className="app-sidebar-logo">
            <img alt="Billpay Logo" src={projectLogo} />
          </NavLink>
        </StyledAppSideBarExpanded>
      </StyledAppSideBarHeader>
    </>
  )
}

export default SidebarHeader
