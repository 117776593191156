import React from 'react'
import { Toolbar, Typography, Container, Box, styled } from '@mui/material'
import { OrgHeaderUserBox, SidebarHeader } from '../../layout-components'
import { box_minimized_style, box_maximized_style } from '../../layout-components/constants'
import { StyledAppBar, AppMainBox } from '../../../utils/styles'

interface Props {
  title: string
  children: React.ReactNode | React.ReactNode[]
}

export const StyledBoxInner = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '1.5rem',
  paddingBottom: '1.5rem',
  display: 'flex',
  flex: 1,
  backgroundColor: '#F5F5F5',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
}))

export const StyledBoxInnerWrapper = styled(Box)(() => ({
  display: 'block',
  width: '100%',
}))

const SidebarWithoutMenu: React.FC<Props> = ({ title, children }: Props) => {
  return (
    <>
      <StyledAppBar>
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <Box sx={box_minimized_style}>
              <SidebarHeader />
            </Box>
            <Box flex={1} sx={box_minimized_style}>
              <Box>
                <Typography>{title}</Typography>
              </Box>
            </Box>
            <Box sx={box_maximized_style}>
              <SidebarHeader />
            </Box>
            <Box flex={1} sx={box_maximized_style}>
              <Box>
                <Typography>{title}</Typography>
              </Box>
            </Box>
            <Box sx={box_maximized_style}>
              <OrgHeaderUserBox />
            </Box>
          </Toolbar>
        </Container>
      </StyledAppBar>
      <AppMainBox>
        <StyledBoxInner>
          <StyledBoxInnerWrapper>{children}</StyledBoxInnerWrapper>
        </StyledBoxInner>
      </AppMainBox>
    </>
  )
}

export default SidebarWithoutMenu
