import React, { ReactNode } from 'react'
import Alert from '@mui/material/Alert'

import { IError } from '../service/interface'

interface Props {
  error: IError | string | null | ReactNode
}

export const getErrorText = (error: IError): string => {
  let errorText = ''
  if (error?.response?.data && typeof error.response.data === 'object') {
    const firstKey = Object.keys(error.response.data)[0]
    errorText = error.response.data[firstKey]
    if (errorText.length) {
      errorText = errorText as string
    } else {
      const secondKey = Object.keys(error.response.data[firstKey])[0]
      errorText = `${error.response.data[firstKey][secondKey]}`
    }
  } else {
    errorText = 'Service temporarily unavailable. Please try again later.'
  }

  return errorText
}

const ErrorAlert: React.FC<Props> = ({ error }) => {
  let errorText: ReactNode = ''

  if (React.isValidElement(error)) {
    errorText = error
  } else if (typeof error === 'string') {
    errorText = error
  } else {
    errorText = getErrorText(error as IError)
  }
  return (
    <Alert severity="error" sx={{ marginTop: 1, marginBottom: 1 }}>
      {errorText}
    </Alert>
  )
}

export default ErrorAlert
