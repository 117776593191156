import React from 'react'
import { Avatar, Box, Grid, Stack, styled, Typography } from '@mui/material'

interface Props {
  time?: string
  username: string
  avatar: string
  message: string
}

const StyledDateTime = styled(Typography)(() => ({
  opacity: 0.6,
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: 12,
}))

const MessageItem: React.FC<Props> = ({ time, username, avatar, message }: Props) => {
  return (
    <Stack direction="row">
      <Box mr={2}>
        <Avatar src={avatar}>{username.charAt(0)}</Avatar>
      </Box>
      <Box flex={1}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row">
              <Box flex={1}>
                <Typography fontWeight={350}>{username}</Typography>
              </Box>
              {time && (
                <Box>
                  <StyledDateTime>{time}</StyledDateTime>
                </Box>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography color="#4F5E7B" fontSize={12}>
                {message}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}

export default MessageItem
