import React from 'react'
import { Redirect, RouteProps } from 'react-router-dom'
import { isUserStaffAdminOrMember } from '../../utils/auth'
import PrivateRoute from './PrivateRoute'

const StaffRoute: React.FC<RouteProps> = ({ ...restProps }: RouteProps) => {
  return isUserStaffAdminOrMember() ? <PrivateRoute {...restProps} /> : <Redirect to="/dashboard" />
}

export default StaffRoute
