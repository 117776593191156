import React from 'react'
import Grid from '@mui/material/Grid'
import loginImg from '../../assets/images/login.jpg'
import Paper from '@mui/material/Paper'

const BaseLayout: React.FC = ({ children }) => {
  return (
    <Grid container component="main" height="100%">
      <Grid
        item
        xs={false}
        md={6}
        sx={{
          backgroundImage: `url(${loginImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid
        item
        xs={12}
        md={6}
        component={Paper}
        elevation={6}
        square
        px={{ xs: 6, sm: 12, md: 6, lg: 12 }}
        py={8}
      >
        {children}
      </Grid>
    </Grid>
  )
}

export default BaseLayout
