import React, { useEffect, useState } from 'react'
import { IDateRange, IListBillRequest, IUserInputSearch } from '../service/interface'

export const defaultDateRange: IDateRange = {
  startDate: undefined,
  endDate: undefined,
}

interface IState {
  params: IListBillRequest
  selectedDateRange: IDateRange
  selectedPaidDateRange: IDateRange
  userInputSearch: IUserInputSearch
  setUserInputSearch: React.Dispatch<React.SetStateAction<IUserInputSearch>>
  setParams: React.Dispatch<React.SetStateAction<IListBillRequest>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setSortOrder: React.Dispatch<React.SetStateAction<string>>
  setSelectedDateRange: React.Dispatch<React.SetStateAction<IDateRange>>
  setSelectedPaidDateRange: React.Dispatch<React.SetStateAction<IDateRange>>
}

export const initialState: IState = {
  userInputSearch: {
    client: null,
    entity: null,
    accountBalances: [],
    vendors: [],
    accounts: [],
    divisions: [],
    paymentMethod: [],
    minAmount: undefined,
    maxAmount: undefined,
    inputMinAmount: '0.00',
    inputMaxAmount: '0.00',
    selectedDateRange: defaultDateRange,
    selectedPaidDateRange: defaultDateRange,
    invoiceStartDate: 'Start Date',
    invoiceEndDate: 'End Date',
    paidStartDate: 'Start Date',
    paidEndDate: 'End Date',
    billStatus: [],
    invoiceName: '',
  },
  params: {
    page_size: 10,
  },
  selectedDateRange: defaultDateRange,
  selectedPaidDateRange: defaultDateRange,
  setParams: () => null,
  setUserInputSearch: () => null,
  setPage: () => null,
  setSortOrder: () => null,
  setSelectedDateRange: () => null,
  setSelectedPaidDateRange: () => null,
}

export const AdvancedSearchContext = React.createContext<IState>(initialState)

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const AdvancedSearchContextProvider: React.FC<Props> = ({ children }) => {
  const [userInputSearch, setUserInputSearch] = useState<IUserInputSearch>(
    initialState.userInputSearch
  )
  const [params, setParams] = useState<IListBillRequest>({
    page_size: 10,
  })
  const [page, setPage] = useState<number>(1)
  const [sortOrder, setSortOrder] = React.useState<string>('')
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>(defaultDateRange)
  const [selectedPaidDateRange, setSelectedPaidDateRange] = useState<IDateRange>(defaultDateRange)

  useEffect(() => {
    setParams((prev) => ({ ...prev, page }))
  }, [page])

  useEffect(() => {
    setParams((prev) => ({ ...prev, ordering: sortOrder }))
  }, [sortOrder])

  return (
    <AdvancedSearchContext.Provider
      value={{
        params,
        selectedDateRange,
        selectedPaidDateRange,
        userInputSearch,
        setUserInputSearch,
        setParams,
        setPage,
        setSortOrder,
        setSelectedDateRange,
        setSelectedPaidDateRange,
      }}
    >
      {children}
    </AdvancedSearchContext.Provider>
  )
}
