import { ActionMap } from '../type'

export enum FooterActionTypes {
  SET_FOOTER_FIXED = 'SET_FOOTER_FIXED',
  SET_FOOTER_SHADOW = 'SET_FOOTER_SHADOW',
  SET_FOOTER_BG_TRANSPARENT = 'SET_FOOTER_BG_TRANSPARENT',
}

type FooterPayload = {
  [FooterActionTypes.SET_FOOTER_FIXED]: boolean
  [FooterActionTypes.SET_FOOTER_SHADOW]: boolean
  [FooterActionTypes.SET_FOOTER_BG_TRANSPARENT]: boolean
}

export type FooterActions = ActionMap<FooterPayload>[keyof ActionMap<FooterPayload>]

export interface FooterState {
  footerFixed: boolean
  footerShadow: boolean
  footerBgTransparent: boolean
}

const footerReducer = (state: FooterState, action: FooterActions) => {
  switch (action.type) {
    case FooterActionTypes.SET_FOOTER_FIXED:
      return {
        ...state,
        footerFixed: action.payload,
      }
    case FooterActionTypes.SET_FOOTER_SHADOW:
      return {
        ...state,
        footerShadow: action.payload,
      }
    case FooterActionTypes.SET_FOOTER_BG_TRANSPARENT:
      return {
        ...state,
        footerBgTransparent: action.payload,
      }
    default:
      return state
  }
}

export default footerReducer
