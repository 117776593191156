import React, { ReactElement } from 'react'
import { Box, Link, Stack, Typography } from '@mui/material'
import WelcomeIcon2 from '../../assets/images/download_app.svg'
import DownloadAppStore from '../../assets/images/download_app_store.svg'
import DownloadGooglePlay from '../../assets/images/download_google_play.svg'
import {
  appStoreLink,
  googlePlayLink,
  plumbBillPayEmail,
} from '../../containers/client/Support/constants'

interface Props {
  title: string
  description: ReactElement
}

const DownloadApp: React.FC<Props> = ({ title, description }) => {
  return (
    <Stack mt={5} paddingTop={10}>
      <Box display="flex" justifyContent="center">
        <img alt="download-app" src={WelcomeIcon2} />
      </Box>
      <Box display="flex" justifyContent="center" mt={5}>
        <Typography fontWeight={400} fontSize={24} align="center">
          {title}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt={1}>
        <Typography align="center">{description}</Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <Link href={appStoreLink}>
          <img alt="dl-app-store" src={DownloadAppStore} />
        </Link>
      </Box>
      <Box display="flex" justifyContent="center" mt={4} mb={5}>
        <Link href={googlePlayLink}>
          <img alt="dl-google-play" src={DownloadGooglePlay} />{' '}
        </Link>
      </Box>
      <Box display="flex" justifyContent="center" mt={5}>
        <Link href={`mailto:${plumbBillPayEmail}`} align="center">
          Contact Support
        </Link>
      </Box>
    </Stack>
  )
}

export default DownloadApp
