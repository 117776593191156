import { eachDayOfInterval, format, addDays, isWeekend } from 'date-fns'
import Holidays from 'date-holidays'

import { EWorkflowState } from '../service/enum'
import {
  IBillsByPaidDateDataPoint,
  ICommitmentLineItemResponse,
  IWorkflowText,
} from '../service/interface'

export const monthsArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const fullMonthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

// Initialize holidays
const holidays = new Holidays('US')

const isHoliday = (date: Date) => {
  const holiday = holidays.isHoliday(date)

  if (holiday) {
    // Filter holidays to include only "public" (non-working) types
    return holiday.some((h) => h.type === 'public')
  }
  return false
}

export const addBusinessDaysWithHolidays = (
  startDate: Date,
  numberOfBusinessDays: number
): Date => {
  let businessDaysAdded = 0
  let currentDate = startDate

  while (businessDaysAdded < numberOfBusinessDays) {
    currentDate = addDays(currentDate, 1) // Move to the next day

    // Skip if the current day is a weekend or holiday
    if (!isWeekend(currentDate) && !isHoliday(currentDate)) {
      businessDaysAdded++
    }
  }

  return currentDate
}

export function generateYearList(yearsAhead?: number): number[] {
  const currentYear = new Date().getFullYear()
  return Array.from({ length: yearsAhead || 50 }, (_, i) => currentYear + i)
}

export type TExtensionTypes =
  | 'pdf'
  | 'txt'
  | 'html'
  | 'img'
  | 'doc'
  | 'docx'
  | 'xlsx'
  | 'xls'
  | 'wav'
export const IMG_FILE_EXT = ['jpeg', 'jpg', 'png', 'gif']
export const validFileTypes = '.pdf,.xls,.xlsx,.doc,.docx,image/*,.wav,video/*'

export const getFormattedCurrency = (amount: number, currency: string) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount / 100)

export const currencyNoDecimalFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
})

const APP_DATE_FORMAT = 'MM/dd/yyyy'
const CHART_DATE_FORMAT = 'MM/dd'

export const MAX_DATA = 10000
export const MAX_AMOUNT = 99999999

export const getFormattedDate = (
  date: string | Date | undefined,
  dateFormat: string = APP_DATE_FORMAT
) => {
  if (!date) {
    return '-'
  }
  let dateObj = date
  if (typeof dateObj === 'string') dateObj = new Date(date)
  dateObj.setMinutes(dateObj.getMinutes() + dateObj.getTimezoneOffset())
  return format(dateObj, dateFormat)
}

export const getFormattedTime = (date: string) =>
  format(new Date(date), `hh:mm 'at' ${APP_DATE_FORMAT}`)

export const getFormattedDateTime = (date: string) =>
  format(new Date(date), `${APP_DATE_FORMAT} 'at' hh:mm a`)

export const getDateTimeDifference = (date: string | Date) => {
  const today = new Date()
  const formatDate = new Date(date)
  const timeMsDiff = today.getTime() - formatDate.getTime()
  let seconds = Math.floor(timeMsDiff / 1000)
  let minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  seconds = seconds % 60
  minutes = minutes % 60
  let timeDiff = ''
  const secondsTextDisplay = seconds > 1 ? 'secs' : 'sec'
  const minutesTextDisplay = minutes > 1 ? 'mins' : 'min'
  const hoursTextDisplay = hours > 1 ? 'hrs' : 'hr'
  if (hours === 0 && minutes === 0) {
    timeDiff = `${seconds} ${secondsTextDisplay} ago`
  } else if (hours === 0) {
    timeDiff = `${minutes} ${minutesTextDisplay} ago`
  } else if (hours < 24) {
    timeDiff = `${hours} ${hoursTextDisplay} ago`
  } else if (hours === 24) {
    timeDiff = '1 day ago'
  } else {
    timeDiff = getFormattedDate(date)
  }
  return timeDiff
}

export const fileNameFromUrl = (url: string) => {
  const matches = url.match(/\/([^\/?#]+)[^\/]*$/)
  if (matches && matches.length > 1) {
    return decodeURIComponent(matches[1].replace(/\+/g, ' '))
  }
  return null
}

export const WorkflowTexts: {
  [key: string]: IWorkflowText
} = {
  [EWorkflowState.client_approval]: {
    acronym: 'CAQ',
    description: 'Client Approval Queue',
  },
  [EWorkflowState.org_approval]: {
    acronym: 'PAQ',
    description: 'Plumb Approval Queue',
  },
  [EWorkflowState.poa_approval]: {
    acronym: 'POA',
    description: 'POA Approval Queue',
  },
  [EWorkflowState.payment_pending]: {
    acronym: 'PPQ',
    description: 'Payment Pending Queue',
  },
  [EWorkflowState.verbal_approval]: {
    acronym: 'CVA',
    description: 'Client Verbal Approval Queue',
  },
  [EWorkflowState.paid]: {
    acronym: 'PAD',
    description: 'Paid',
  },
  [EWorkflowState.rejected]: {
    acronym: 'REJ',
    description: 'Rejected',
  },
  [EWorkflowState.pre_bill]: {
    acronym: 'PRE',
    description: 'Pre-Bills',
  },
}

export const convertDollarsToCents = (amount: string) =>
  Math.round(Number(amount.replace(/[^0-9.-]+/g, '')) * 100)

export const convertDataPointsToLookup = (
  dataPoints: IBillsByPaidDateDataPoint[] = []
): { [key: string]: number } => {
  return dataPoints.reduce(
    (dict, dataPoint) => ({
      ...dict,
      [getFormattedDate(dataPoint.date, CHART_DATE_FORMAT)]: dataPoint.paid_count,
    }),
    {}
  )
}

export const getChartData = (
  dataPoints: IBillsByPaidDateDataPoint[] = [],
  startDate: Date,
  endDate: Date
) => {
  const lookup = convertDataPointsToLookup(dataPoints)
  const dateArray = eachDayOfInterval({ start: startDate, end: endDate }).map((day) =>
    format(day, CHART_DATE_FORMAT)
  )
  return dateArray.map((date) => ({ date, billsPaid: lookup[date] ?? 0 }))
}

export const capitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const removeSpecialCharacters = (amount: string) => amount.replace(/[^\d.-]/g, '')

export const getLocaleDateString = (date: Date) => {
  const localeDate = date.toLocaleDateString('en')
  const [month, day, year] = localeDate.split('/')
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

export const getPercentageValue = (value: string | undefined) => {
  if (value) return Number(value.replace('%', ''))
  return 0
}

export const getFileExtension = (filename: string) => {
  const removeTrailing = filename.substring(0, filename.lastIndexOf('?')) || filename
  // get file extension
  const extension = removeTrailing.substring(
    removeTrailing.lastIndexOf('.') + 1,
    removeTrailing.length
  )
  return extension
}

export const getOrdinalSuffix = (number: number): string => {
  const lastDigit = number % 10
  const lastTwoDigits = number % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return number + 'th'
  }

  switch (lastDigit) {
    case 1:
      return number + 'st'
    case 2:
      return number + 'nd'
    case 3:
      return number + 'rd'
    default:
      return number + 'th'
  }
}

export const getClientEmailAddress = (emailAddress: string): string => {
  const addressTo = emailAddress.split('@')
  return addressTo[0].split('bp.')?.pop() + '@' + addressTo.pop()
}

export const chartFormatNumber = (num: number): string => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M'
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'k'
  }
  return num.toFixed(2)
}

export const chartFormatNumberDecimal = (num: number): string => {
  if (num >= 1000000) {
    return (num / 1000000).toString().replace(/\.0$/, '') + 'M'
  }
  if (num >= 10000) {
    return (num / 1000).toString().replace(/\.0$/, '') + 'k'
  }
  return num.toString()
}

export const getTextWidth = (
  text: string,
  fontSize = 12,
  fontWeight = 350,
  fontFamily = 'sans-serif'
): number => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) {
    return 0
  }

  context.font = `${fontWeight} ${fontSize}px ${fontFamily}`
  const metrics = context.measureText(text)

  return metrics.width
}

export const removeStringSpaces = (value: string) => {
  return value.replace(/\s+/g, '')
}

export const getFormattedDecimal = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
})

/**
 * Parses a date string or Date object and adjusts it to the local timezone without changing the date.
 * This function is specifically useful for cases where only the date part is important and not the time of day.
 * It corrects the issue where parsing a date string might result in a date that is offset due to local timezone differences,
 * particularly when the local timezone is behind UTC. This function should not be used for date-times where the time part is significant.
 *
 * @param {string | Date} dateString - The date string (in ISO format) or Date object to be parsed.
 * @returns {Date} A Date object adjusted to the local timezone, keeping the date part consistent with the input.
 */
export const parseWithoutTZ = (dateString: string | Date | null) => {
  if (!dateString) return ''
  const dateObj = new Date(dateString)
  dateObj.setMinutes(dateObj.getMinutes() + dateObj.getTimezoneOffset())
  return dateObj
}

export const getFormattedTimezoneDate = (dateString: string | Date) => {
  const dateObj = new Date(dateString).toLocaleDateString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
  return dateObj
}

export const isDateLessThanToday = (dateToCompare: Date): boolean => {
  // Get the current date and time
  const now = new Date()
  const todayDate = now.getDate()
  const todayMonth = now.getMonth() + 1
  const todayYear = now.getFullYear()

  const startDate = dateToCompare.getDate()
  const startDateMonth = dateToCompare.getMonth() + 1
  const startDateYear = dateToCompare.getFullYear()

  if (startDateYear < todayYear) {
    return true
  } else if (startDateYear === todayYear && startDateMonth < todayMonth) {
    return true
  } else if (
    startDateYear === todayYear &&
    startDateMonth === todayMonth &&
    startDate < todayDate
  ) {
    return true
  } else if (Number.isNaN(startDateYear)) {
    return true
  }

  return false
}

export const formatCommitmentLineItems = (lineItems: Array<ICommitmentLineItemResponse>) => {
  let counter_idx = 0
  return lineItems.map((item) => {
    counter_idx = counter_idx + 1
    return {
      id: counter_idx.toString(),
      account: item.account?.name,
      division: item.division.name,
      account_guid: item.account?.guid,
      division_guid: item.division.guid,
    }
  })
}

export const formatNumberWithCommas = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatVerificationStatus = (verificationStatus: string) => {
  let formatted = verificationStatus.replace(/_/g, ' ')

  formatted = formatted
    .split('. ')
    .map((sentence) => sentence.charAt(0).toUpperCase() + sentence.slice(1))
    .join('. ')

  return formatted
}

export const formatVendorType = (vendorType: string) => {
  let formatted
  if (vendorType === 'legal_entity') formatted = 'Legal Entity'
  else if (vendorType === 'private_individual') formatted = 'Private Individual'
  else {
    formatted = '-'
  }

  return formatted
}

export function formatPhoneNumber(phoneNumber: string | undefined | null): string {
  if (!phoneNumber) return ''

  // Remove any non-numeric characters
  let cleanedPhoneNumber = phoneNumber.replace(/^\+1/, '')
  cleanedPhoneNumber = cleanedPhoneNumber.replace(/\D/g, '')

  // Ensure the phone number has 10 digits
  if (cleanedPhoneNumber.length !== 10) {
    return `Invalid (${phoneNumber}) `
  }

  // Format the phone number as xxx-xxx-xxxx
  return cleanedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}
