import React from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { TwoFASetupStepProps } from './interface'
import CustomDialog from '../../../../components/CustomDialog'
import { E2FAMethod } from '../../../../service/enum'

const TwoFaSelectMethod: React.FC<TwoFASetupStepProps> = ({
  open,
  onClose,
  onBack,
  onNext,
  faMethod,
  setFaMethod,
}) => {
  return (
    <CustomDialog
      open={open}
      title="Two Factor Authentication Setup"
      onClose={onClose}
      buttonCloseOnly
      contentDividers
      actions={
        <>
          <Button variant="outlined" onClick={onBack}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" onClick={onNext} disabled={faMethod === ''}>
            Next
          </Button>
        </>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography mb={2} fontSize={16} fontWeight={350}>
            Select method
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <RadioGroup
              aria-labelledby="kick-off-radio-group"
              name="kick-off-radio-group"
              value={faMethod}
              defaultValue=""
            >
              <Stack direction="row">
                <FormControlLabel
                  value={E2FAMethod.sms}
                  key="select-sms"
                  control={
                    <Radio
                      onChange={(event) => {
                        setFaMethod(event.target.value)
                      }}
                    />
                  }
                  label="Text"
                  sx={{ marginBottom: 2 }}
                />
                <FormControlLabel
                  value={E2FAMethod.email}
                  key="select-email"
                  control={
                    <Radio
                      onChange={(event) => {
                        setFaMethod(event.target.value)
                      }}
                    />
                  }
                  label="Email"
                  sx={{ marginBottom: 2 }}
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </CustomDialog>
  )
}

export default TwoFaSelectMethod
