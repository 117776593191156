import React, { FormEventHandler } from 'react'
import { Breakpoint, useTheme } from '@mui/material/styles'
import { Dialog, DialogActions, DialogContent, useMediaQuery } from '@mui/material'
import { DialogTitleWithClose } from './modals/DialogTitleWithClose'

interface FormWrapperProps {
  onSubmit?: FormEventHandler
}

const ConditionalFormWrapper: React.FC<FormWrapperProps> = ({ onSubmit, children }) => {
  return onSubmit ? <form onSubmit={onSubmit}>{children}</form> : <>{children}</>
}

interface Props {
  open: boolean
  onClose?: () => void
  // If provided, the Dialog contents will be wrapped in a form
  // using the ConditionalFormWrapper component.
  formOnSubmit?: FormWrapperProps['onSubmit']
  buttonCloseOnly?: boolean
  title: React.ReactNode
  contentDividers?: boolean
  actions?: React.ReactNode
  maxWidth?: Breakpoint
}

const CustomDialog: React.FC<Props> = ({
  open,
  onClose,
  formOnSubmit,
  buttonCloseOnly,
  children,
  title,
  contentDividers,
  actions,
  maxWidth = 'sm',
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      onClose={!buttonCloseOnly ? onClose : undefined}
    >
      <ConditionalFormWrapper onSubmit={formOnSubmit}>
        <DialogTitleWithClose onClose={buttonCloseOnly ? onClose : undefined}>
          {title}
        </DialogTitleWithClose>
        <DialogContent dividers={contentDividers}>{children}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </ConditionalFormWrapper>
    </Dialog>
  )
}

export default CustomDialog
