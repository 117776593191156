import { useQuery, useMutation, UseQueryOptions } from 'react-query'

import {
  createOrgUser,
  destroyOrgUser,
  enableOrgUser,
  listOrgDesignatedEntities,
  listOrgUsers,
  partialUpdateOrgUser,
  retrieveOrgUser,
} from '../api'
import {
  IError,
  IListOrgUsersResponse,
  IOrgUser,
  IUpdateOrgUserRequest,
  ICreateOrgUserRequest,
  IUserResponse,
  IListUserRequest,
  IListEntityResponse,
} from '../interface'

export const OrgUserQueryKeys = {
  GET_ORG_USERS: 'GET_ORG_USERS',
  GET_ORG_USER: 'GET_ORG_USER',
  GET_ORG_USER_DESIGNATED_ENTITIES: 'GET_ORG_USER_DESIGNATED_ENTITIES',
}

export const useOrgUsers = (orgGuid: string, params: IListUserRequest) =>
  useQuery<IListOrgUsersResponse, IError>([OrgUserQueryKeys.GET_ORG_USERS, orgGuid, params], () =>
    listOrgUsers(orgGuid, params)
  )

export const useOrgUser = (orgGuid: string, userGuid: string) =>
  useQuery<IOrgUser, IError>(
    [OrgUserQueryKeys.GET_ORG_USER, orgGuid, userGuid],
    () => retrieveOrgUser(orgGuid, userGuid),
    { enabled: !!orgGuid }
  )

export const useCreateOrgUser = () =>
  useMutation<IUserResponse, IError, ICreateOrgUserRequest>(createOrgUser)

export const useUpdateOrgUser = () =>
  useMutation<
    IOrgUser,
    IError,
    { orgGuid: string; userGuid: string; payload: IUpdateOrgUserRequest }
  >(partialUpdateOrgUser)

export const useDestroyOrgUser = () =>
  useMutation<undefined, IError, { orgGuid: string; userGuid: string }>(destroyOrgUser)

export const useEnableOrgUser = () =>
  useMutation<undefined, IError, { orgGuid: string; userGuid: string }>(enableOrgUser)

export const useOrgDesignatedEntityList = (
  orgGuid: string,
  userGuid: string,
  has_commitments?: boolean,
  has_bills_approved?: boolean,
  has_bills_waiting_approval?: boolean,
  config?: UseQueryOptions<IListEntityResponse, IError>
) =>
  useQuery<IListEntityResponse, IError>(
    [
      OrgUserQueryKeys.GET_ORG_USER_DESIGNATED_ENTITIES,
      { orgGuid, userGuid, has_commitments, has_bills_approved, has_bills_waiting_approval },
    ],
    () =>
      listOrgDesignatedEntities(
        orgGuid,
        userGuid,
        has_commitments,
        has_bills_approved,
        has_bills_waiting_approval
      ),
    config
  )
