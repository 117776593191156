import { AppBar, Box, Button, Switch, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const bgGray500 = '#f3f3f3'
export const bgWhite = '#FFFFFF'
export const textGray = '#394A64'
export const textWhite = '#FFFFFF'

export const StyledSwitch = styled(Switch)(() => ({
  '.css-1swxfd6-MuiButtonBase-root-MuiSwitch-switchBase': {
    color: '#2B388D',
  },
  '.css-1yjjitx-MuiSwitch-track': {
    backgroundColor: 'rgba(43, 56, 141, 0.08)',
  },
}))

export const StyledArchive = styled(Typography)(() => ({
  color: '#D32F2F',
  fontWeight: '350',
}))

export const StyledBtnArchive = styled(Button)(() => ({
  borderColor: '#D32F2F',
  borderRadius: '4px',
  '&:hover': { borderColor: '#D32F2F' },
}))

export const StyledBtnSaveNew = styled(Button)(() => ({
  '&:disabled': {
    background: '#2B388D !important',
  },
}))

export const BtnLink = styled(Button)(() => ({
  boxShadow: 'none',
  color: '#3b3e66',
  textDecoration: 'none',
  '&:hover': {
    color: '#1271AF',
    background: 'transparent !important',
    'span:before': {
      visibility: 'visible',
      transform: 1,
    },
  },
  span: {
    position: 'relative',
    transition: '$transition-base',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '2px',
      bottom: 0,
      left: 0,
      visibility: 'hidden',
      transform: 0,
      transition: 'all 0.3s ease-in-out 0s',
    },
  },
  '&:disabled': {
    color: '#d1d2db',
  },
}))

export const TextUpload = styled(Typography)(() => ({
  width: '212px',
  height: '20px',
  marginLeft: 'calc(50% - 330px/2 + 62.5px)',

  /* Paragraph Regular / Regular */
  fontStyle: 'normal',
  fontWeight: 350,
  lineHeight: '130%',

  color: '#2B388D',
}))

export const MessageInputStyle = styled(Box)(() => ({
  height: '50px',
  position: 'relative',
  input: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    fontSize: '16px',
    padding: '15px 20px',
    border: '1px solid #dddddd',
    borderRadius: '5px',
  },
  button: {
    position: 'absolute',
    right: '10px',
    top: '8px',
    padding: '8px',
  },
}))

export const BtnIcon = styled(Button)(() => ({
  boxShadow: '0 0.25rem 0.55rem rgba(18, 113, 175, 0.35)',
  color: '#fff',
  background: '#1271AF',
  '&:hover': {
    background: '#1271AF',
  },
}))

export const BtnAddAttachment = styled(Button)(() => ({
  width: '150px',
  height: '30px',
  background: '#2B388D',
  borderRadius: '2px',
  '&:hover': {
    background: '#2B388D',
  },
  '&:disabled': {
    background: '#2B388D !important',
  },
}))

export const TextDataDisplay = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  /* identical to box height, or 21px */
  display: 'flex',
  alignItems: 'center',
  /* Grey / 5 */
  color: '#394A64',
}))

export const ButtonPrimary = styled(Button)(() => ({
  boxShadow: '0 0.25rem 0.55rem rgba(43, 56, 141, 0.35)',
  color: '#fff',
  background: '#2B388D',
  '&:hover': {
    background: '#2B388D',
  },
}))

export const ButtonDanger = styled(Button)(() => ({
  boxShadow: '0 0.25rem 0.55rem rgba(192, 80, 39, 0.35)',
  color: '#fff',
  background: '#C05027',
  '&:hover': {
    background: '#C05027',
  },
}))

export const AppMainBox = styled(Box)(() => ({
  display: 'flex',
  flex: '1 auto',
  minWidth: 0,
  flexDirection: 'column',
  position: 'relative',
  minHeight: '100vh',
}))

export const StyledAppBar = styled(AppBar)(() => ({
  position: 'static',
  backgroundColor: '#2B388D',
  padding: '0 !important',
}))

export const StyledAppSideBarExpanded = styled(Box)(() => ({
  display: 'inline',
  alignItems: 'left',
  transition: 'all 0.5s $app-sidebar-transition',
}))

export const StyledAppSideBarCollapsed = styled(Box)(() => ({
  display: 'none',
  alignItems: 'left',
  transition: 'all 0.5s $app-sidebar-transition',
}))

export const StyledAppSideBarHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: ' space-between',
  position: 'relative',
  backgroundColor: '#2B388D',
  marginRight: '25px',
  '&.active:after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    backgroundColor: 'white',
    left: 0,
    bottom: 0,
  },
}))

export const StyledConfirmationText = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 350,
  color: 'rgba(0, 0, 0, 0.60)',
}))

export const StyledLabelName = styled(Typography)(() => ({
  fontFamily: 'Roboto, sans-serif',
  fontSize: 12,
  fontWeight: 400,
  color: '#394A64',
  marginBottom: 3,
}))

export const CustomSwitchQbooks = styled(Switch)(() => ({
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 3,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#2B388D',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        color: 'white',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#2B388D',
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    color: '#2B388D',
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: 'rgba(43, 56, 141, 0.08)',
    opacity: 1,
    transition: {
      duration: 500,
    },
  },
}))
