import React from 'react'
import { Box, Typography } from '@mui/material'

const NotificationHeader: React.FC = () => {
  return (
    <Box display="flex" alignItems="flex-end">
      <Typography fontSize={16} fontWeight={350}>
        Messages
      </Typography>
    </Box>
  )
}

export default NotificationHeader
