import React from 'react'
import { Box, CircularProgress, styled } from '@mui/material'

const StyledLoader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}))

const LoadingIndicator: React.FC = () => (
  <StyledLoader data-testid="spinner">
    <CircularProgress />
  </StyledLoader>
)

export default LoadingIndicator
