import { Typography } from '@mui/material'
import React from 'react'
import DownloadApp from '../../../components/DownloadApp'

const LoginMobileView: React.FC = () => {
  return (
    <DownloadApp
      title="Please continue to the Plumb Bill Pay app"
      description={
        <Typography align="center">
          Download or open our mobile app for
          <br />
          the best user experience.
        </Typography>
      }
    />
  )
}

export default LoginMobileView
