import { UseQueryOptions, useMutation, useQuery } from 'react-query'
import {
  getOutlookIntegrationStatus,
  createOutlookIntegration,
  updateOutlookIntegrationEmail,
  qbDesktopBillRetrieve,
  qbOnlineOauthRedirect,
  qbOnlineOauthConnect,
  qbOnlineCompanyRetrieve,
  qbOnlineBillRetrieve,
  qbDesktopUnclaimedCompanyFilesRetrieve,
  qbdClaimCompanyFile,
  qbdCompanyFileRetrieve,
  qbdCompanyFileUpdate,
  qbOnlineSync,
  getOutlookClientIntegrationStatus,
  qbOnlineBillList,
  qbDesktopBillList,
  removeOutlookEmail,
  verifyOutlookIntegrationEmail,
  qbOnlineClientRetrieve,
} from '../api'
import {
  IError,
  ICreateEmailIntegrationResponse,
  IQBOCompanyResponse,
  IQBORedirectResponse,
  IQBDCompanyFileResponse,
  IQBDCompanyFileUpdateRequest,
  IListQBDCompanyFileResponse,
  IQBListResponse,
  IQBBillData,
  IOutlookEmailRequest,
} from '../interface'
import { AxiosError } from 'axios'

export const IntegrationQueryKeys = {
  GET_OUTLOOK_STATUS: 'GET_OUTLOOK_STATUS',
  GET_QB_ID: 'GET_QB_ID',
  GET_QB_LIST: 'GET_QB_LIST',
  GET_QBO_REDIRECT: 'GET_QBO_REDIRECT',
  GET_QBO_COMPANY: 'GET_QBO_COMPANY',
  GET_QBO_BILL: 'GET_QBO_BILL',
  GET_QBO_BILL_LIST: 'GET_QBO_BILL_LIST',
  GET_QBD_COMPANY_FILE: 'GET_QBD_COMPANY_FILE',
}

export const useClientOutlookStatus = (clientGuid: string) =>
  useQuery<ICreateEmailIntegrationResponse, AxiosError>(
    [IntegrationQueryKeys.GET_OUTLOOK_STATUS, clientGuid],
    () => getOutlookClientIntegrationStatus(clientGuid)
  )

export const useOutlookStatus = (
  clientGuid: string,
  config?: UseQueryOptions<ICreateEmailIntegrationResponse, AxiosError>
) =>
  useQuery<ICreateEmailIntegrationResponse, AxiosError>(
    [IntegrationQueryKeys.GET_OUTLOOK_STATUS, clientGuid],
    () => getOutlookIntegrationStatus(clientGuid),
    config
  )

export const useCreateOutlookIntegration = () =>
  useMutation<
    ICreateEmailIntegrationResponse,
    IError,
    { clientGuid: string; email_address: string; should_verify?: boolean }
  >(createOutlookIntegration)

export const useVerifyOutlookEmail = () =>
  useMutation<ICreateEmailIntegrationResponse, IError, { email_address: string }>(
    verifyOutlookIntegrationEmail
  )

export const useUpdateOutlookEmail = () =>
  useMutation<
    ICreateEmailIntegrationResponse,
    IError,
    { clientGuid: string; payload: IOutlookEmailRequest }
  >(updateOutlookIntegrationEmail)

export const useRemoveOutlookEmail = () =>
  useMutation<null, IError, { clientGuid: string }>(removeOutlookEmail)

export const useListQb = (
  bills: string,
  page: number,
  page_size: number,
  config?: UseQueryOptions<IQBListResponse, IError, IQBListResponse>
) =>
  useQuery<IQBListResponse, IError>(
    [IntegrationQueryKeys.GET_QB_LIST, { bills, page, page_size }],
    () => qbDesktopBillList(bills, page, page_size),
    config
  )

export const useRetrieveQb = (
  billGuid: string,
  config?: UseQueryOptions<IQBBillData, IError, IQBBillData>
) =>
  useQuery<IQBBillData, IError>(
    [IntegrationQueryKeys.GET_QB_ID, { billGuid }],
    () => qbDesktopBillRetrieve(billGuid),
    config
  )

export const useGetQBORedirect = (entityGuid: string) =>
  useQuery<IQBORedirectResponse, IError>(
    [IntegrationQueryKeys.GET_QBO_REDIRECT, { entityGuid }],
    () => qbOnlineOauthRedirect(entityGuid)
  )

export const useQBOConnect = () =>
  useMutation<
    IQBOCompanyResponse,
    IError,
    { entityGuid: string; realmId: string; authCode: string }
  >(qbOnlineOauthConnect)

export const useQBOSync = () =>
  useMutation<IQBOCompanyResponse, IError, { entityGuid: string }>(qbOnlineSync)

export const useQBOCompanyRetrieve = (entityGuid: string) =>
  useQuery<IQBOCompanyResponse, IError>(
    [IntegrationQueryKeys.GET_QBO_COMPANY, { entityGuid }],
    () => qbOnlineCompanyRetrieve(entityGuid),
    { retry: false }
  )

export const useUnclaimedQBDCompanyFilesRetrieve = () =>
  useQuery<IListQBDCompanyFileResponse, IError>(
    [IntegrationQueryKeys.GET_QBO_COMPANY, {}],
    () => qbDesktopUnclaimedCompanyFilesRetrieve(),
    { retry: false }
  )

export const useClaimQBDCompanyFile = () => {
  return useMutation<IQBDCompanyFileResponse, IError, { company: string; entityGuid: string }>(
    qbdClaimCompanyFile
  )
}

export const useQBDCompanyFileRetrieve = (entityGuid: string) =>
  useQuery<IQBDCompanyFileResponse, IError>(
    [IntegrationQueryKeys.GET_QBD_COMPANY_FILE, { entityGuid }],
    () => qbdCompanyFileRetrieve(entityGuid),
    { retry: false }
  )

export const useQBDCompanyFileUpdate = () =>
  useMutation<IQBDCompanyFileResponse, IError, IQBDCompanyFileUpdateRequest>(qbdCompanyFileUpdate)

export const useQBOClientConnectRetrieve = (clientGuid: string) =>
  useQuery<{ is_connected: boolean }, IError>(
    [IntegrationQueryKeys.GET_QBD_COMPANY_FILE, { clientGuid }],
    () => qbOnlineClientRetrieve(clientGuid),
    { retry: false }
  )

export const useListQBOBills = (
  type: 'bporg' | 'client',
  guid: string,
  bills: string,
  page: number,
  page_size: number,
  config?: UseQueryOptions<IQBListResponse, IError, IQBListResponse>
) =>
  useQuery<IQBListResponse, IError>(
    [IntegrationQueryKeys.GET_QBO_BILL_LIST, { bills, page, page_size }],
    () => qbOnlineBillList(type, guid, bills, page, page_size),
    config
  )

export const useRetrieveQBOBill = (
  type: 'bporg' | 'client',
  guid: string,
  billGuid: string,
  config?: UseQueryOptions<IQBBillData, IError, IQBBillData>
) =>
  useQuery<IQBBillData, IError>(
    [IntegrationQueryKeys.GET_QBO_BILL, { billGuid }],
    () => qbOnlineBillRetrieve(type, guid, billGuid),
    config
  )
