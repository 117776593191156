import { clientRoutesPath } from '../../routes/ClientRoutes'

export const box_minimized_style = {
  display: { xs: 'flex', md: 'none' },
}

export const box_maximized_style = {
  display: { xs: 'none', md: 'flex' },
}

export const menu_style = {
  display: { xs: 'block', md: 'none' },
}

type Page = {
  name: string
  url: string
  disabled?: boolean
  subPages?: { name: string; url: string; disabled?: boolean }[]
  activeUrl?: string[]
}

export const org_pages: Page[] = [
  { name: 'Clients', url: '/clients', activeUrl: ['clients'] },
  {
    name: 'BillPay',
    url: '#',
    subPages: [
      { name: 'Bills', url: '/billing' },
      { name: 'Commitments', url: '/commitments?page=1' },
      { name: 'Parent Vendors', url: '/parent-vendors' },
    ],
    activeUrl: ['billing', 'commitments', 'parent-vendors'],
  },
  {
    name: 'Workflow',
    url: '#',
    subPages: [
      { name: 'Reconciliation', url: '/workflow/reconciliation-reminders' },
      { name: 'Review', url: '/reviews', disabled: true },
      { name: 'Report Reminders', url: '/workflow/report-reminders' },
    ],
    activeUrl: [
      'reports?report-tab=2',
      'reports?report-tab=1',
      'reviews',
      'report-reminder',
      'reconciliation',
      'workflow',
    ],
  },
  {
    name: 'Reporting',
    url: '/reports?report-tab=0',
    activeUrl: ['reports?report-tab=0', 'reports/client'],
  },
]

export const org_pages_without_vendor_management: Page[] = [
  { name: 'Clients', url: '/clients', activeUrl: ['clients'] },
  {
    name: 'BillPay',
    url: '#',
    subPages: [
      { name: 'Bills', url: '/billing' },
      { name: 'Commitments', url: '/commitments' },
    ],
    activeUrl: ['billing', 'commitments', 'parent-vendors'],
  },
  {
    name: 'Workflow',
    url: '#',
    subPages: [
      { name: 'Reconciliation', url: '/workflow/reconciliation-reminders' },
      { name: 'Review', url: '/reviews', disabled: true },
      { name: 'Report Reminders', url: '/workflow/report-reminders' },
    ],
    activeUrl: [
      'reports?report-tab=2',
      'reports?report-tab=1',
      'reviews',
      'report-reminder',
      'reconciliation',
      'workflow',
    ],
  },
  {
    name: 'Reporting',
    url: '/reports?report-tab=0',
    activeUrl: ['reports?report-tab=0', 'reports/client'],
  },
]

export const client_pages: Page[] = [
  { name: 'Bills', url: `${clientRoutesPath.bills + '/all-entities'}`, activeUrl: ['bill'] },
  {
    name: 'Commitments',
    url: `${clientRoutesPath.commitments + '/all-entities'}`,
    activeUrl: ['commitments'],
  },
  {
    name: 'Reports',
    url: `${clientRoutesPath.reports}`,
    activeUrl: ['reports'],
  },
]
