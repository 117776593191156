import React from 'react'
import { Toolbar, Typography, Container, Box } from '@mui/material'
import { SidebarHeader } from '../../layout-components'
import { box_minimized_style, box_maximized_style } from '../../layout-components/constants'
import { StyledAppBar, AppMainBox } from '../../../utils/styles'
import { StyledBoxInner, StyledBoxInnerWrapper } from '../SidebarWithoutMenu'
import ClientHeaderUserBox from '../../layout-components/ClientHeaderUserbox'

interface Props {
  title: string
  children: React.ReactNode | React.ReactNode[]
}

const LogoHeaderNoMenu: React.FC<Props> = ({ title, children }: Props) => {
  return (
    <>
      <StyledAppBar>
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <Box sx={box_minimized_style}>
              <SidebarHeader />
            </Box>
            <Box flex={1} sx={box_minimized_style}>
              <Box>
                <Typography>{title}</Typography>
              </Box>
            </Box>
            <Box sx={box_maximized_style}>
              <SidebarHeader />
            </Box>
            <Box flex={1} sx={box_maximized_style}>
              <Box>
                <Typography>{title}</Typography>
              </Box>
            </Box>
            <Box sx={box_maximized_style}>
              <ClientHeaderUserBox />
            </Box>
          </Toolbar>
        </Container>
      </StyledAppBar>
      <AppMainBox>
        <StyledBoxInner>
          <StyledBoxInnerWrapper>{children}</StyledBoxInnerWrapper>
        </StyledBoxInner>
      </AppMainBox>
    </>
  )
}

export default LogoHeaderNoMenu
