import React from 'react'
import { Box, Divider } from '@mui/material'

import NotificationItem from './NotificationItem'
import { IBillMessage, IError, UserType } from '../../service/interface'
import LoadingIndicator from '../loading/LoadingIndicator'
import ErrorAlert from '../ErrorAlert'

interface Props {
  messages: IBillMessage[]
  isLoading: boolean
  guid: string // client or org guid
  userType: UserType
  isError: boolean
  error: IError | null
  handleCloseSidebar: () => void
}

const Notifications: React.FC<Props> = ({
  messages,
  isLoading,
  guid,
  userType,
  isError,
  error,
  handleCloseSidebar,
}) => {
  return (
    <Box>
      {isLoading && <LoadingIndicator />}
      {isError && <ErrorAlert error={error as IError} />}
      {!isLoading &&
        messages.map((data, idx) => (
          <React.Fragment key={idx}>
            <NotificationItem
              message={data.body}
              time={data.created_on}
              isUnread={data.unread}
              billGuid={data.bill.guid}
              guid={guid}
              messageGuid={data.guid}
              userType={userType}
              username={`${data.from_user.first_name} ${data.from_user.last_name}`}
              avatar={data.from_user.avatar}
              handleCloseSidebar={handleCloseSidebar}
            />
            <Divider />
          </React.Fragment>
        ))}
    </Box>
  )
}

export default Notifications
