import { ActionMap } from '../type'

export enum ModalActionTypes {
  SHOW_MODAL = 'SHOW_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export enum ModalScrollType {
  BODY = 'body',
  PAPER = 'paper',
}

export interface IShowModalPayload {
  show?: boolean
  content: any
  title: string
  actionRightBar?: any
  subTitle?: string
  showDivider?: boolean
  hideCloseButton?: boolean
  overflow?: string
  isClosable?: boolean
  scroll?: ModalScrollType
}

type ModalPayload = {
  [ModalActionTypes.SHOW_MODAL]: IShowModalPayload
  [ModalActionTypes.CLOSE_MODAL]: undefined
}

export type ModalActions = ActionMap<ModalPayload>[keyof ActionMap<ModalPayload>]

export interface ModalState {
  show: boolean
  content: any
  title: string
  actionRightBar: any
  subTitle: string
  showDivider: boolean
  hideCloseButton: boolean
  overflow: string
  isClosable: boolean
  scroll?: ModalScrollType
}

const modalReducer = (state: ModalState, action: ModalActions) => {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL:
      return {
        actionRightBar: '',
        subTitle: '',
        showDivider: true,
        hideCloseButton: false,
        overflow: 'auto',
        show: true,
        isClosable: true,
        scroll: ModalScrollType.BODY,
        ...action.payload,
      }
    case ModalActionTypes.CLOSE_MODAL:
      return {
        show: false,
        content: null,
        actionRightBar: '',
        title: '',
        subTitle: '',
        showDivider: true,
        hideCloseButton: false,
        overflow: 'auto',
        isClosable: true,
        scroll: ModalScrollType.BODY,
      }
    default:
      return state
  }
}

export default modalReducer
