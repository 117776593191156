import { useMutation, useQuery, UseQueryOptions } from 'react-query'
import { listOrganizations, partialUpdateOrganization, retrieveOrganization } from '../api'
import { IError, IOrganization, IUpdateOrganizationRequest } from '../interface'

export const OrganizationQueryKeys = {
  GET_ORGANIZATIONS: 'GET_ORGANIZATIONS',
  GET_ORGANIZATION: 'GET_ORGANIZATION',
}

export const useOrganizations = (page: number) =>
  useQuery([OrganizationQueryKeys.GET_ORGANIZATIONS, page], () => listOrganizations(page))

export const useOrganization = (orgGuid: string, config?: UseQueryOptions<IOrganization, IError>) =>
  useQuery<IOrganization, IError>(
    [OrganizationQueryKeys.GET_ORGANIZATION, orgGuid],
    () => retrieveOrganization(orgGuid),
    config
  )

export const useUpdateOrganization = () =>
  useMutation<undefined, IError, IUpdateOrganizationRequest>(partialUpdateOrganization)
