import { ActionMap } from '../type'

export enum SidebarActionTypes {
  SET_SIDEBAR_TOGGLE_MOBILE = 'SET_SIDEBAR_TOGGLE_MOBILE',
  SET_SIDEBAR_SHADOW = 'SET_SIDEBAR_SHADOW',
  SET_SIDEBAR_STYLE = 'SET_SIDEBAR_STYLE',
  SET_SIDEBAR_FOOTER = 'SET_SIDEBAR_FOOTER',
  SET_SIDEBAR_TOGGLE = 'SET_SIDEBAR_TOGGLE',
  SET_SIDEBAR_FIXED = 'SET_SIDEBAR_FIXED',
  SET_SIDEBAR_USERBOX = 'SET_SIDEBAR_USERBOX',
  SET_SIDEBAR_TYPE = 'SET_SIDEBAR_TYPE',
}

export enum SidebarType {
  ADMIN = 'ADMIN',
  NAVIGATION = 'NAVIGATION',
  ORGLIST = 'ORGLIST',
}

type SidebarPayload = {
  [SidebarActionTypes.SET_SIDEBAR_TOGGLE_MOBILE]: boolean
  [SidebarActionTypes.SET_SIDEBAR_SHADOW]: boolean
  [SidebarActionTypes.SET_SIDEBAR_STYLE]: string
  [SidebarActionTypes.SET_SIDEBAR_FOOTER]: boolean
  [SidebarActionTypes.SET_SIDEBAR_TOGGLE]: boolean
  [SidebarActionTypes.SET_SIDEBAR_FIXED]: boolean
  [SidebarActionTypes.SET_SIDEBAR_USERBOX]: boolean
  [SidebarActionTypes.SET_SIDEBAR_TYPE]: SidebarType
}

export type SidebarActions = ActionMap<SidebarPayload>[keyof ActionMap<SidebarPayload>]

export interface SidebarState {
  sidebarFixed: boolean
  sidebarFooter: boolean
  sidebarShadow: boolean
  sidebarStyle: string
  sidebarUserbox: boolean
  sidebarToggleMobile: boolean
  sidebarToggle: boolean
  sidebarType: SidebarType
}

const sidebarReducer = (state: SidebarState, action: SidebarActions) => {
  switch (action.type) {
    case SidebarActionTypes.SET_SIDEBAR_TOGGLE_MOBILE:
      return {
        ...state,
        sidebarToggleMobile: action.payload,
      }
    case SidebarActionTypes.SET_SIDEBAR_SHADOW:
      return {
        ...state,
        sidebarShadow: action.payload,
      }
    case SidebarActionTypes.SET_SIDEBAR_STYLE:
      return {
        ...state,
        sidebarStyle: action.payload,
      }
    case SidebarActionTypes.SET_SIDEBAR_FOOTER:
      return {
        ...state,
        sidebarFooter: action.payload,
      }
    case SidebarActionTypes.SET_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarToggle: action.payload,
      }
    case SidebarActionTypes.SET_SIDEBAR_FIXED:
      return {
        ...state,
        sidebarFixed: action.payload,
      }
    case SidebarActionTypes.SET_SIDEBAR_USERBOX:
      return {
        ...state,
        sidebarUserbox: action.payload,
      }
    case SidebarActionTypes.SET_SIDEBAR_TYPE:
      return {
        ...state,
        sidebarType: action.payload,
      }
    default:
      return state
  }
}

export default sidebarReducer
