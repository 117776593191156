import React from 'react'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IShowModalPayload,
  ModalScrollType,
  ModalState,
} from '../contexts/ModalContext/modalReducer'

export interface IModalState extends ModalState {
  showModal: (payload: IShowModalPayload) => void
  hideModal: () => void
}

interface EnahancedModalProps {
  state: IModalState
}

const EnhancedModal: React.FC<EnahancedModalProps> = ({ state }) => {
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('md'))
  const {
    show,
    content,
    title,
    actionRightBar,
    subTitle,
    showDivider = true,
    hideCloseButton = false,
    overflow,
    isClosable,
    scroll = ModalScrollType.BODY,
    hideModal,
  } = state

  if (!show) return <></>
  return (
    <Dialog
      scroll={scroll}
      open={true}
      fullScreen={fullscreen}
      onClose={(event, reason) => {
        if (isClosable && reason !== 'backdropClick') {
          hideModal()
        }
      }}
      classes={{
        paper: clsx(`border-0 shadow-sm-dark bg-white p-xl-0 m-0 overflow-${overflow}`, {
          rounded: !fullscreen,
        }),
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        py={2}
        px={3}
        sx={{ borderBottom: showDivider ? '1px solid #eeeff8' : 'none' }}
      >
        <Box>
          <Typography fontSize={24}>{title}</Typography>
          <Typography color="#7a7b97">{subTitle}</Typography>
        </Box>
        <Box ml={4}>
          {actionRightBar}
          {!hideCloseButton && (
            <Button variant="text" onClick={hideModal}>
              <FontAwesomeIcon icon="times" opacity={0.5} />
            </Button>
          )}
        </Box>
      </Box>
      {content}
    </Dialog>
  )
}

export default EnhancedModal
