import React from 'react'
import HowToIcon from '../../../assets/images/support/howtouse.png'
import AppSupportIcon from '../../../assets/images/support/appsupport.png'
import SecurityIcon from '../../../assets/images/support/security.png'
import TechSupportIcon from '../../../assets/images/support/techsupport.png'
import { clientRoutesPath } from '../../../routes/ClientRoutes'
import { Link, Typography } from '@mui/material'
import { PLUMB_EMAIL } from '../../../utils/constants'

export const appStoreLink = 'https://apps.apple.com/us/app/plumb-billpay/id6447216735'
export const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.plumbbillpay.app'
export const plumbBillPayEmail = PLUMB_EMAIL
export const plumbEmailDisplay = (
  <Link href={`mailto:${plumbBillPayEmail}`} display="inline">
    {plumbBillPayEmail}
  </Link>
)

export const customAnswers = {
  panel_how_to_use_12: (
    <Typography>For any profile updates, please email {plumbEmailDisplay}</Typography>
  ),
  panel_app_support_1: (
    <Typography>For any user updates, please email {plumbEmailDisplay} </Typography>
  ),
  panel_tech_supp_1: (
    <Typography>
      Use the customer service email {plumbEmailDisplay} or call your Plumb Bill Pay Team.{' '}
    </Typography>
  ),
  panel_how_to_use_10: (
    <Typography>
      Bills should always move out of the approval queue once they have been acted on. If it appears
      to be stuck, please re-enter the approval screen and try again. If this does not solve the
      problem, please contact support at {plumbEmailDisplay} or call your Plumb Bill Pay Team.{' '}
    </Typography>
  ),
  panel_app_support_2: (
    <>
      <Typography>
        You can change your notification settings on the Mobile App by going into More {'>'}{' '}
        Notification Settings and selecting the event for which you’d like to edit your
        notifications. On the Web App, notification options can be found under your My Profile {'>'}{' '}
        Notification Settings. You can choose to receive in-app notifications, email notifications,
        both, or none.
      </Typography>
      <Typography mt={2}>
        If you opt-in to receive in-app notifications, please ensure that your mobile device
        settings are set appropriately:
      </Typography>
      <Typography>
        For iPhone directions, click{' '}
        <Link
          href="https://support.apple.com/guide/iphone/change-notifications-iph62faab6a4/ios"
          display="inline"
        >
          here
        </Link>
        .
      </Typography>
      <Typography>
        For Android directions, click{' '}
        <Link href="https://support.google.com/android/answer/9079661?hl=en" display="inline">
          here
        </Link>
        .
      </Typography>
      <Typography mt={2}>
        If you choose to receive no notifications, please be aware that you might miss important
        bill pay updates and messages, including notifications of bills awaiting your approval. We
        suggest that you allow notifications for Bills to avoid any delays in payment. If you do not
        wish to receive these notifications, we recommend that you have a designee who can receive
        them in your place. If you’d like to add a designee to your account, please email{' '}
        {plumbEmailDisplay} or your Plumb Team for assistance.
      </Typography>
    </>
  ),
  panel_how_to_use_9: (
    <Typography>
      If you see anything in your set-up that you do not recognize or did not approve, please
      contact your{' '}
      <Typography fontWeight={350} style={{ fontStyle: 'italic' }} display="inline">
        assigned
      </Typography>{' '}
      Bill Pay Team immediately.
    </Typography>
  ),
}

export const supportData = [
  {
    title: 'How to Use the App',
    icon: HowToIcon,
    faqs: [
      {
        panel: 'panel_how_to_use_1',
        question: 'How do I upload a new bill?',
        answer:
          'You can email the bill to your designated Plumb email address, or you can upload the bill directly into the app by clicking “Upload a Bill for Processing” on the top right section of the dashboard. Your Plumb team will process the bill on your behalf.',
        link: [],
      },
      {
        panel: 'panel_how_to_use_2',
        question: 'How do I approve or reject a bill?',
        answer:
          'You are able to approve or reject a bill from the list of Pending Bills on the dashboard, or from the bill detail screen',
        link: [],
      },
      {
        panel: 'panel_how_to_use_3',
        question: 'What happens when I approve a bill?',
        answer:
          'When you approve a bill, it will go into your pending payment queue.  Once paid by your Plumb team it will move into your paid queue.',
        link: [],
      },
      {
        panel: 'panel_how_to_use_4',
        question: 'What happens when I reject a bill?',
        answer:
          'When you reject a bill, it is placed in your rejected list and it will not be sent for payment.',
        link: [],
      },
      {
        panel: 'panel_how_to_use_5',
        question: 'I accidentally approved or rejected a bill. How can I fix that?',
        answer:
          'If you make a mistake, please contact your Plumb Bill Pay Team as soon as possible, either by phone or emailing to your client email address.  They will help get the bill corrected.',
        link: [],
      },
      {
        panel: 'panel_how_to_use_6',
        question: 'How can I view reports in the app?',
        answer: 'You can select “Reports” from the main navigation at the top of the screen',
        link: [
          {
            title: 'Reports Page',
            to: clientRoutesPath.reports,
          },
        ],
      },
      {
        panel: 'panel_how_to_use_7',
        question: 'How can I view all my paid or pending bills?',
        answer: 'All paid and pending bills can be viewed from the Bills screen.',
        link: [
          {
            title: 'Bills Page',
            to: clientRoutesPath.bills + '/all-entities',
          },
        ],
      },
      {
        panel: 'panel_how_to_use_8',
        question: 'I see a mistake in one of my bill entries. How can I fix that?',
        answer:
          'All bill updates are handled by your Plumb Bill Pay Team. To request an update, select the message option within the bill entry and request any updates that are needed. Your Plumb Bill Pay Team will be notified, and will make all requested changes. Once completed, you will receive the updated bill in your approval queue.',
        link: [],
      },
      {
        panel: 'panel_how_to_use_9',
        question:
          'I don’t recognize the name of a designee or entity on my account.  How can I figure that out?',
        answer: plumbBillPayEmail,
        link: [],
      },
      {
        panel: 'panel_how_to_use_10',
        question:
          "I already approved or rejected a bill but it's still showing up. Why is this happening?",
        answer: `Bills should always move out of the approval queue once they have been acted on.  “If this does not solve the problem, please contact support at ${plumbBillPayEmail}`,
        link: [],
      },
      {
        panel: 'panel_how_to_use_11',
        question: 'How can I change the date range on my dashboard?',
        answer:
          'You can customize the date range by selecting the date selector on the upper right corner of the dashboard.',
        link: [],
      },
      {
        panel: 'panel_how_to_use_12',
        question: 'How can I edit my profile page?',
        answer: `For any profile updates, please email ${plumbBillPayEmail}`,
        link: [],
      },
    ],
  },
  {
    title: 'App Support',
    icon: AppSupportIcon,
    faqs: [
      {
        panel: 'panel_app_support_1',
        question:
          'How can I give access to my financial advisor or other designee(s) so they can approve & reject bills on my behalf',
        answer: `For any user updates, please email ${plumbBillPayEmail}`,
        link: [],
      },
      {
        panel: 'panel_app_support_2',
        question: 'How can I change my notifications?',
        answer: plumbBillPayEmail,
        link: [],
      },
    ],
  },
  {
    title: 'Safety',
    icon: SecurityIcon,
    faqs: [
      {
        panel: 'panel_security_1',
        question: 'How safe is your app?',
        answer:
          'We utilize bank-level security and conduct consistent audits to ensure your financial privacy and security. In addition, Plumb benefits from the rigid security practices of our industry-leading vendors such as Apple, Google, and AWS.',
        link: [],
      },
      {
        panel: 'panel_security_2',
        question: 'How are you able to connect to my bank account?',
        answer:
          'The App itself does not connect to your bank account. The approvals you authorize within the App are routed to your accounting platform for secure payment.',
        link: [],
      },
      {
        panel: 'panel_security_3',
        question: 'Who has access to my banking and financial information?',
        answer:
          'Only those you authorize directly will have access to your banking and financial information. ',
        link: [],
      },
    ],
  },
  {
    title: 'Tech Support',
    icon: TechSupportIcon,
    faqs: [
      {
        panel: 'panel_tech_supp_1',
        question: 'How do I contact customer service?',
        answer: `Use the customer service email ${plumbBillPayEmail} or call your Plumb Bill Pay Team.`,
        link: [],
      },
      {
        panel: 'panel_tech_supp_2',
        question: 'How do I change or reset my password?',
        answer:
          'Refer to How can I edit my profile page under the How to Use the App Section or follow this link:',
        link: [
          {
            title: 'Profile Page',
            to: clientRoutesPath.profile,
          },
        ],
      },
      {
        panel: 'panel_tech_supp_3',
        question: 'I forgot my password.  What do I do?',
        answer:
          'Navigate to the login page, enter your username and click on forgot password.  Follow the directions to reset your password. ',
        link: [],
      },
      {
        panel: 'panel_tech_supp_4',
        question: 'How can I download the app to use on my mobile device?',
        answer:
          'The Plumb Bill Pay app is available in the Apple App Store and the Google Play Store.',
        link: [
          {
            title: 'Apple App Store',
            to: appStoreLink,
          },
          {
            title: 'Google Play Store',
            to: googlePlayLink,
          },
        ],
      },
    ],
  },
]

export const profileEditAnswer = [
  {
    title: 'Add a photo',
    description:
      'You can add a photo to your profile by clicking the photo icon on the upper left corner of the profile page and uploading a photo of your choice.',
  },
  {
    title: 'Password',
    description:
      'You can reset your password by clicking “Update Password” on the profile page. Follow the directions provided.',
  },
  {
    title: 'First or Last Name',
    description:
      'If you would like to update the name on your account, please contact your Client Relationship Manager directly.',
  },
  {
    title: 'Email',
    description:
      'If you would like to update your email address, please contact your Client Relationship Manager directly.',
  },
  {
    title: 'Adding or Editing or Removing Designees',
    description:
      'If you would like to add a new designee, edit an existing designee, or remove a designee, please contact your Client Relationship Manager directly.',
  },
  {
    title: 'Two Factor Authorization (2FA)',
    description:
      'We strongly recommend that you set-up your 2FA to allow for the most secure user experience. If you have any questions about this process, or run into any issues, please contact your Client Relationship Manager directly.',
  },
]
