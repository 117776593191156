import React, { useReducer } from 'react'
import modalReducer, { IShowModalPayload, ModalActionTypes, ModalScrollType } from './modalReducer'
import { useHistory } from 'react-router-dom'
import EnhancedModal, { IModalState } from '../../components/EnahancedModal'

interface IProps {
  children?: React.ReactNode | React.ReactNode[]
}

const initialState: IModalState = {
  show: false,
  content: '',
  actionRightBar: '',
  title: '',
  subTitle: '',
  showDivider: true,
  hideCloseButton: false,
  overflow: 'auto',
  isClosable: true,
  scroll: ModalScrollType.BODY,
  hideModal: () => null,
  showModal: () => null,
}

export const ModalContext = React.createContext<IModalState>(initialState)

export const ModalContextProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(modalReducer, initialState)
  const history = useHistory()

  const hideModal = () => {
    dispatch({
      type: ModalActionTypes.CLOSE_MODAL,
    })
    history.replace({ search: '' })
  }
  const showModal = (payload: IShowModalPayload) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL,
      payload,
    })
  }

  return (
    <ModalContext.Provider value={{ ...state, hideModal, showModal }}>
      {children}
      <EnhancedModal state={{ ...state, hideModal, showModal }} />
    </ModalContext.Provider>
  )
}
