import React, { useContext } from 'react'
import clsx from 'clsx'

import { ThemeOptionsContext } from '../../../contexts/ThemeOptions'
import { SidebarActionTypes } from '../../../contexts/ThemeOptions/sidebarReducer'
import OrgSidebarMenu from '../../layout-components/SidebarMenu/OrgSidebarMenu'
import ClientSidebarMenu from '../../layout-components/SidebarMenu/ClientSidebarMenu'
import { Box } from '@mui/material'
import { StyledBoxInner, StyledBoxInnerWrapper } from '../SidebarWithoutMenu'
import { AppMainBox } from '../../../utils/styles'

interface Props {
  userType: string
}

const DefaultSidebar: React.FC<Props> = ({ children, userType }) => {
  const { state, dispatch } = useContext(ThemeOptionsContext)

  const {
    footer: { footerFixed },
    sidebar: { sidebarToggle, sidebarToggleMobile, sidebarFixed },
    header: { headerFixed, headerSearchHover, headerDrawerToggle },
    mainContent: { contentBackground },
  } = state

  const toggleSidebarMobile = () => {
    dispatch({
      type: SidebarActionTypes.SET_SIDEBAR_TOGGLE_MOBILE,
      payload: !sidebarToggleMobile,
    })
  }

  return (
    <>
      <Box
        className={clsx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover,
        })}
      >
        <Box>
          <Box
            onClick={toggleSidebarMobile}
            className={clsx('app-sidebar-overlay', {
              'is-active': sidebarToggleMobile,
            })}
          />
        </Box>
        <AppMainBox>
          {userType === 'org' ? <OrgSidebarMenu /> : <ClientSidebarMenu />}

          <StyledBoxInner>
            <StyledBoxInnerWrapper>{children}</StyledBoxInnerWrapper>
          </StyledBoxInner>
        </AppMainBox>
      </Box>
    </>
  )
}

export default DefaultSidebar
