import { useMutation, useQuery } from 'react-query'
import {
  addOrgGroupUser,
  bulkCreateGroupPermission,
  createBulkGroup,
  createGroupPermission,
  createOrgGroup,
  destroyGroupPermission,
  destroyGroupUser,
  destroyOrgGroup,
  listGroupPermissions,
  listGroups,
  listGroupsUsers,
  partialUpdateOrgGroup,
  userOrgGroupList,
} from '../api'
import {
  IAddUserInGroupRequest,
  IBulkCreateOrgGroupPermissionCreateRequest,
  ICreateOrgGroupPermissionCreateRequest,
  IError,
  IGroup,
  IGroupRequest,
  IGuidWithName,
  IListGroupPermissionResponse,
  IListGuidWithName,
  IListOrgGroupUserResponse,
  IListUserOrgGroupPermission,
  IOrgGroupPermissionSlug,
  IOrgGroupUserGuid,
} from '../interface'

export const OrgGroupQueryKeys = {
  GET_ORG_GROUPS: 'GET_ORG_GROUPS',
  GET_ORG_GROUP_USERS: 'GET_ORG_GROUP_USERS',
  GET_ORG_GROUP_PERMISSIONS: 'GET_ORG_GROUP_PERMISSIONS',
  GET_ORG_USER_GROUP_PERMISSIONS: 'GET_ORG_USER_GROUP_PERMISSIONS',
}

export const useListOrgGroups = (
  orgGuid: string,
  page: number,
  pageSize: number,
  search?: string
) =>
  useQuery<IListGuidWithName, IError>(
    [OrgGroupQueryKeys.GET_ORG_GROUPS, { orgGuid, page, pageSize, search }],
    () => listGroups(orgGuid, page, pageSize, search)
  )

export const useCreateOrgGroup = () =>
  useMutation<IGuidWithName, IError, { orgGuid: string; name: string }>(createOrgGroup)

export const useDestoryOrgGroup = () =>
  useMutation<IGuidWithName, IError, { orgGuid: string; groupGuid: string }>(destroyOrgGroup)

export const useUpdateOrgGroup = () =>
  useMutation<undefined, IError, { orgGuid: string; groupGuid: string; orgGroupName: string }>(
    partialUpdateOrgGroup
  )

export const useDestoryOrgGroupUser = () =>
  useMutation<undefined, IError, IOrgGroupUserGuid>(destroyGroupUser)

export const useAddOrgUserInGroup = () =>
  useMutation<undefined, IError, IAddUserInGroupRequest>(addOrgGroupUser)

export const useCreateBulkGroup = () => useMutation<IGroup, IError, IGroupRequest>(createBulkGroup)

export const useListOrgGroupUsers = (
  orgGuid: string,
  groupGuid: string,
  page: number,
  pageSize: number
) =>
  useQuery<IListOrgGroupUserResponse, IError>(
    [OrgGroupQueryKeys.GET_ORG_GROUP_USERS, { orgGuid }],
    () => listGroupsUsers(orgGuid, groupGuid, page, pageSize)
  )

export const useListOrgGroupPermissions = (orgGuid: string, groupGuid: string) =>
  useQuery<IListGroupPermissionResponse, IError>(
    [OrgGroupQueryKeys.GET_ORG_GROUP_PERMISSIONS, { orgGuid, groupGuid }],
    () => listGroupPermissions(orgGuid, groupGuid)
  )

export const useCreateOrgGroupPermission = () =>
  useMutation<undefined, IError, ICreateOrgGroupPermissionCreateRequest>(createGroupPermission)

export const useBulkCreateOrgGroupPermission = () =>
  useMutation<undefined, IError, IBulkCreateOrgGroupPermissionCreateRequest>(
    bulkCreateGroupPermission
  )

export const useDestroyOrgGroupPermission = () =>
  useMutation<undefined, IError, IOrgGroupPermissionSlug>(destroyGroupPermission)

export const useListUserOrgGroups = (
  orgGuid: string,
  userGuid: string,
  page?: number,
  pageSize?: number
) =>
  useQuery<IListUserOrgGroupPermission, IError>(
    [OrgGroupQueryKeys.GET_ORG_USER_GROUP_PERMISSIONS, { orgGuid, userGuid, page, pageSize }],
    () => userOrgGroupList(orgGuid, userGuid, page, pageSize),
    { retry: !orgGuid ? false : undefined }
  )
