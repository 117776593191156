import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Badge, Box, Button, Grid, Stack, Typography } from '@mui/material'

import { getFormattedDateTime } from '../../utils/helpers'
import { isBpOrgUser } from '../../utils/auth'
import {
  OrgBillQueryKeys,
  useMarkOrgBillMessageAsRead,
  useRetrieveOrgBill,
} from '../../service/hooks/orgBill'
import {
  ClientBillQueryKeys,
  useMarkClientBillMessageAsRead,
  useRetrieveClientBill,
} from '../../service/hooks/clientBill'
import { useQueryClient } from 'react-query'
import { UserType } from '../../service/interface'
import MessageItem from '../ExistingBill/Messages/MessageItem'

interface Props {
  time: string
  message: string
  isUnread: boolean
  billGuid: string
  messageGuid: string
  userType: UserType
  guid: string // client or org guid
  username: string
  avatar: string
  handleCloseSidebar: () => void
}

const useRetrieveBillData = {
  bporg: useRetrieveOrgBill,
  client: useRetrieveClientBill,
}

const NotificationItem: React.FC<Props> = ({
  time,
  message,
  isUnread,
  billGuid,
  userType,
  guid,
  messageGuid,
  username,
  avatar,
  handleCloseSidebar,
}: Props) => {
  const isOrgUser = isBpOrgUser()
  const queryClient = useQueryClient()
  const { mutateAsync: requestMarkClientBMAsRead } = useMarkClientBillMessageAsRead()
  const { mutateAsync: requestMarkOrgBMAsRead } = useMarkOrgBillMessageAsRead()
  const { data: billData } = useRetrieveBillData[userType](guid, billGuid)
  const location = useLocation()
  const history = useHistory()

  const handleViewBillOnClick = async () => {
    const billDetailUrl =
      userType === 'bporg'
        ? `/clients/${guid}/${userType}/bill/${billGuid}?billTab=1`
        : `/bill/${billGuid}?billTab=1?userType=client`
    handleCloseSidebar()
    history.push(billDetailUrl, { prevLocation: location })

    if (isUnread) {
      if (isOrgUser) {
        await requestMarkOrgBMAsRead({
          guid,
          billGuid,
          messageGuid,
        })
        queryClient.refetchQueries([OrgBillQueryKeys.GET_GLOBAL_MESSAGE_LIST, guid])
        queryClient.refetchQueries([OrgBillQueryKeys.GET_BILL_MESSAGE_SUMMARY, guid])
      } else {
        await requestMarkClientBMAsRead({
          guid,
          billGuid,
          messageGuid,
        })
        queryClient.refetchQueries([ClientBillQueryKeys.GET_GLOBAL_MESSAGE_LIST, guid])
        queryClient.refetchQueries([ClientBillQueryKeys.GET_BILL_MESSAGE_SUMMARY, guid])
      }
    }
  }

  return (
    <Grid container mb={3}>
      <Grid item xs={12}>
        <Stack direction="row">
          <Box mt={1} mr={1}>
            {isUnread && <Badge variant="dot" badgeContent=" " color="error" />}
          </Box>
          <Box>
            <Button onClick={handleViewBillOnClick}>
              <Typography fontSize={14} color="#212121">
                Invoice #{billData?.ext_bill_id}
              </Typography>
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Box p={1} ml={1} onClick={handleViewBillOnClick}>
          <MessageItem
            time={getFormattedDateTime(time)}
            username={username}
            avatar={avatar}
            message={message}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default NotificationItem
