import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import ClientDefaultSidebar from '../components/layout-blueprints/ClientDefaultSidebar'
import LoadingIndicator from '../components/loading/LoadingIndicator'
import PrivateRoute from '../components/route/PrivateRoute'
import { ClientGuidContextProvider } from '../contexts/ClientGuidContext'
import LogoHeaderNoMenu from '../components/layout-blueprints/LogoHeaderNoMenu'

const TermsConditions = lazy(() => import('../containers/general/TermsConditions'))
const Dashboard = lazy(() => import('../containers/client/Dashboard'))
const Bills = lazy(() => import('../containers/client/Bills'))
const Reports = lazy(() => import('../containers/client/Reports'))
const Profile = lazy(() => import('../containers/general/Profile'))
const BillDetails = lazy(() => import('../containers/client/Bills/BillDetails'))
const Support = lazy(() => import('../containers/client/Support'))
const Onboarding = lazy(() => import('../components/modals/client/WelcomeModal'))
const Commitments = lazy(() => import('../containers/client/Commitments'))
const SelectClient = lazy(() => import('../containers/client/SelectClient'))
const AdvancedSearch = lazy(() => import('../containers/client/AdvancedSearch'))
const OnboardingChecklist = lazy(() => import('../containers/client/Checklist'))

export const clientRoutesPath = {
  dashboard: '/dashboard',
  profile: '/profile',
  bills: '/bills', // equal to pending
  bill: '/bill',
  termsConditions: '/terms-conditions',
  reports: '/reports',
  support: '/support',
  onboarding: '/onboarding',
  commitments: '/commitments',
  selectClient: '/select-client',
  advancedSearch: '/advanced-search',
  clientChecklist: '/client-onboarding',
}

const {
  dashboard,
  profile,
  bills,
  bill,
  termsConditions,
  reports,
  support,
  onboarding,
  commitments,
  selectClient,
  advancedSearch,
  clientChecklist,
} = clientRoutesPath

export const clientBillRoutes = {
  billsPrebill: (entityGuid: string) => `/bills/${entityGuid}/client-pre-bill`,
  billsApproved: (entityGuid: string) => `/bills/${entityGuid}/approved`,
  billsAutoApproved: (entityGuid: string) => `/bills/${entityGuid}/auto-approved`,
  billsRejected: (entityGuid: string) => `/bills/${entityGuid}/rejected`,
  billsPaid: (entityGuid: string) => `/bills/${entityGuid}/paid`,
}

const ClientRoutes = (
  <Route path={Object.values(clientRoutesPath)}>
    <ClientGuidContextProvider>
      <Route
        path={[
          dashboard,
          profile,
          bills,
          bill,
          termsConditions,
          reports,
          support,
          onboarding,
          commitments,
          selectClient,
          advancedSearch,
        ]}
      >
        <Suspense fallback={<LoadingIndicator />}>
          <Switch>
            <PrivateRoute exact path={clientRoutesPath.selectClient} component={SelectClient} />
            <ClientDefaultSidebar>
              <PrivateRoute exact path={clientRoutesPath.onboarding} component={Onboarding} />
              <PrivateRoute
                exact
                path={clientRoutesPath.termsConditions}
                component={TermsConditions}
              />
              <PrivateRoute exact path={clientRoutesPath.dashboard} component={Dashboard} />
              <PrivateRoute path={clientRoutesPath.bills} component={Bills} />
              <PrivateRoute
                exact
                path={`${clientRoutesPath.bill}/:billGuid`}
                component={BillDetails}
              />
              <PrivateRoute path={clientRoutesPath.reports} component={Reports} />
              <PrivateRoute path={clientRoutesPath.support} component={Support} />
              <PrivateRoute exact path={clientRoutesPath.profile} component={Profile} />
              <PrivateRoute
                exact
                path={`${clientRoutesPath.commitments}/:entityGuid`}
                component={Commitments}
              />
              <PrivateRoute
                exact
                path={`${clientRoutesPath.advancedSearch}`}
                component={AdvancedSearch}
              />
            </ClientDefaultSidebar>
          </Switch>
        </Suspense>
      </Route>
      <Route path={[clientChecklist]}>
        <LogoHeaderNoMenu title="">
          <Switch>
            <Suspense fallback={<LoadingIndicator />}>
              <PrivateRoute
                exact
                path={`${clientChecklist}/client`}
                component={OnboardingChecklist}
              />
            </Suspense>
          </Switch>
        </LogoHeaderNoMenu>
      </Route>
    </ClientGuidContextProvider>
  </Route>
)

export default ClientRoutes
