import React, { useEffect, useState } from 'react'
import PhoneNumberStep from './PhoneNumberStep'
import { TwoFASetupStepProps } from './interface'
import VerifyCodeStep from './VerifyCodeStep'
import TwoFaSelectMethod from './TwoFaSelectMethod'
import { E2FAMethod } from '../../../../service/enum'

const steps: React.FC<TwoFASetupStepProps>[] = [TwoFaSelectMethod, PhoneNumberStep, VerifyCodeStep]

interface Props {
  userEmail?: string
  open: boolean
  existingTwoFaMethod?: string
  onClose: () => void
}

const TwoFASetup: React.FC<Props> = ({ userEmail, open, existingTwoFaMethod, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [faValue, setFaValue] = useState('')
  const [faMethod, setFaMethod] = useState('')

  useEffect(() => {
    if (existingTwoFaMethod) {
      setFaMethod(existingTwoFaMethod)
      if (existingTwoFaMethod === E2FAMethod.email && userEmail) {
        setFaValue(userEmail)
      }
    }
  }, [existingTwoFaMethod, userEmail])

  useEffect(() => {
    if (faMethod === E2FAMethod.email && userEmail) {
      setFaValue(userEmail)
    }
  }, [userEmail, faMethod])

  const handleClose = () => {
    setFaValue('')
    setFaMethod('')
    setCurrentStep(0)
    onClose()
  }

  const handleBack = () => {
    if (currentStep === 0) {
      handleClose()
    } else {
      setCurrentStep((prev) => prev - 1)
    }
  }

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1)
    } else {
      setCurrentStep(0)
      handleClose()
    }
  }

  const CurrentStepComponent = steps[currentStep]

  return (
    <CurrentStepComponent
      open={open}
      onClose={handleClose}
      onBack={handleBack}
      onNext={handleNext}
      faValue={faValue}
      setFaValue={setFaValue}
      faMethod={faMethod}
      setFaMethod={setFaMethod}
      isUpdateFa={!!existingTwoFaMethod}
    />
  )
}

export default TwoFASetup
