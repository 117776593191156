import { ActionMap } from '../type'

export enum PageTitleActionTypes {
  SET_PAGE_TITLE_STYLE = 'SET_PAGE_TITLE_STYLE',
  SET_PAGE_TITLE_BACKGROUND = 'SET_PAGE_TITLE_BACKGROUND',
  SET_PAGE_TITLE_SHADOW = 'SET_PAGE_TITLE_SHADOW',
  SET_PAGE_TITLE_ICON_BOX = 'SET_PAGE_TITLE_ICON_BOX',
  SET_PAGE_TITLE_DESCRIPTION = 'SET_PAGE_TITLE_DESCRIPTION',
}

type PageTitlePayload = {
  [PageTitleActionTypes.SET_PAGE_TITLE_STYLE]: string
  [PageTitleActionTypes.SET_PAGE_TITLE_BACKGROUND]: string
  [PageTitleActionTypes.SET_PAGE_TITLE_SHADOW]: boolean
  [PageTitleActionTypes.SET_PAGE_TITLE_ICON_BOX]: boolean
  [PageTitleActionTypes.SET_PAGE_TITLE_DESCRIPTION]: boolean
}

export type PageTitleAction = ActionMap<PageTitlePayload>[keyof ActionMap<PageTitlePayload>]

export interface PageTitleState {
  pageTitleStyle: string
  pageTitleBackground: string
  pageTitleShadow: boolean
  pageTitleIconBox: boolean
  pageTitleDescription: boolean
}

const pageTitleReducer = (state: PageTitleState, action: PageTitleAction) => {
  switch (action.type) {
    case PageTitleActionTypes.SET_PAGE_TITLE_STYLE:
      return {
        ...state,
        pageTitleStyle: action.payload,
      }
    case PageTitleActionTypes.SET_PAGE_TITLE_BACKGROUND:
      return {
        ...state,
        pageTitleBackground: action.payload,
      }
    case PageTitleActionTypes.SET_PAGE_TITLE_SHADOW:
      return {
        ...state,
        pageTitleShadow: action.payload,
      }
    case PageTitleActionTypes.SET_PAGE_TITLE_ICON_BOX:
      return {
        ...state,
        pageTitleIconBox: action.payload,
      }
    case PageTitleActionTypes.SET_PAGE_TITLE_DESCRIPTION:
      return {
        ...state,
        pageTitleDescription: action.payload,
      }
    default:
      return state
  }
}

export default pageTitleReducer
