import { ActionMap } from '../type'

export enum HeaderActionTypes {
  SET_HEADER_FIXED = 'SET_HEADER_FIXED',
  SET_HEADER_SHADOW = 'SET_HEADER_SHADOW',
  SET_HEADER_BG_TRANSPARENT = 'SET_HEADER_BG_TRANSPARENT',
  SET_HEADER_SEARCH_HOVER = 'SET_HEADER_SEARCH_HOVER',
  SET_HEADER_DRAWER_TOGGLE = 'SET_HEADER_DRAWER_TOGGLE',
}

type HeaderPayload = {
  [HeaderActionTypes.SET_HEADER_FIXED]: boolean
  [HeaderActionTypes.SET_HEADER_SHADOW]: boolean
  [HeaderActionTypes.SET_HEADER_BG_TRANSPARENT]: boolean
  [HeaderActionTypes.SET_HEADER_DRAWER_TOGGLE]: boolean
  [HeaderActionTypes.SET_HEADER_SEARCH_HOVER]: boolean
}

export type HeaderActions = ActionMap<HeaderPayload>[keyof ActionMap<HeaderPayload>]

export interface HeaderState {
  headerFixed: boolean
  headerShadow: boolean
  headerBgTransparent: boolean
  headerSearchHover: boolean
  headerDrawerToggle: boolean
}

const headerReducer = (state: HeaderState, action: HeaderActions) => {
  switch (action.type) {
    case HeaderActionTypes.SET_HEADER_FIXED:
      return {
        ...state,
        headerFixed: action.payload,
      }
    case HeaderActionTypes.SET_HEADER_SHADOW:
      return {
        ...state,
        headerShadow: action.payload,
      }
    case HeaderActionTypes.SET_HEADER_BG_TRANSPARENT:
      return {
        ...state,
        headerBgTransparent: action.payload,
      }
    case HeaderActionTypes.SET_HEADER_DRAWER_TOGGLE:
      return {
        ...state,
        headerDrawerToggle: action.payload,
      }
    case HeaderActionTypes.SET_HEADER_SEARCH_HOVER:
      return {
        ...state,
        headerSearchHover: action.payload,
      }
    default:
      return state
  }
}

export default headerReducer
