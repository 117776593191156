import React, { useContext } from 'react'
import { Badge, Box, Button } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'

import { SidebarContext } from '../../../contexts/SidebarContext'
import NotificationHeader from '../../Notifications/NotificationHeader'
import { isBpOrgUser } from '../../../utils/auth'
import OrgNotifications from '../../Notifications/OrgNotifications'
import ClientNotifications from '../../Notifications/ClientNotifications'

interface Props {
  unreadAmount: number
  guid: string
}

const HeaderDots: React.FC<Props> = ({ unreadAmount, guid }) => {
  const isOrgUser = isBpOrgUser()
  const { showDrawer, handleClose } = useContext(SidebarContext)

  const handleNotificationClick = () => {
    showDrawer({
      header: <NotificationHeader />,
      content: isOrgUser ? (
        <OrgNotifications guid={guid} userType="bporg" handleCloseSidebar={handleClose} />
      ) : (
        <ClientNotifications guid={guid} userType="client" handleCloseSidebar={handleClose} />
      ),
    })
  }

  return (
    <>
      <Box mr={1}>
        <Button onClick={handleNotificationClick}>
          <Badge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            badgeContent={unreadAmount}
            classes={{ badge: 'bg-success badge-circle' }}
          >
            <MailIcon style={{ color: 'white' }} />
          </Badge>
        </Button>
      </Box>
    </>
  )
}

export default HeaderDots
