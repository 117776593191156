import { ActionMap } from '../type'

export enum MainContentActionTypes {
  SET_CONTENT_BACKGROUND = 'SET_CONTENT_BACKGROUND',
  SET_THEME_CONFIGURATOR_TOGGLE = 'SET_THEME_CONFIGURATOR_TOGGLE',
}

type MainContentPayload = {
  [MainContentActionTypes.SET_CONTENT_BACKGROUND]: string
  [MainContentActionTypes.SET_THEME_CONFIGURATOR_TOGGLE]: boolean
}

export type MainContentActions = ActionMap<MainContentPayload>[keyof ActionMap<MainContentPayload>]

export interface MainContentState {
  contentBackground: string
  themeConfiguratorToggle: boolean
}

const mainContentReducer = (state: MainContentState, action: MainContentActions) => {
  switch (action.type) {
    case MainContentActionTypes.SET_CONTENT_BACKGROUND:
      return {
        ...state,
        headerFixed: action.payload,
      }
    case MainContentActionTypes.SET_THEME_CONFIGURATOR_TOGGLE:
      return {
        ...state,
        headerShadow: action.payload,
      }
    default:
      return state
  }
}

export default mainContentReducer
