import React from 'react'

import Notifications from '.'
import { useClientGlobalMessageList } from '../../service/hooks/clientBill'
import { UserType } from '../../service/interface'

interface Props {
  guid: string
  userType: UserType
  handleCloseSidebar: () => void
}

const ClientNotifications: React.FC<Props> = ({ guid, userType, handleCloseSidebar }) => {
  const { data, isLoading, isError, error } = useClientGlobalMessageList(guid)

  return (
    <Notifications
      messages={data?.results || []}
      isLoading={isLoading}
      guid={guid}
      userType={userType}
      handleCloseSidebar={handleCloseSidebar}
      isError={isError}
      error={error}
    />
  )
}

export default ClientNotifications
