import React, { useLayoutEffect, useMemo, useState } from 'react'

import LoadingIndicator from '../components/loading/LoadingIndicator'
import { useListAssignedClients } from '../service/hooks/clientBill'
import {
  loadClientGuid,
  loadClientCreatedOn,
  saveClientGuid,
  saveClientCreatedOn,
  loadClientName,
  saveClientName,
  loadClientRole,
  saveClientRole,
  loadIsOnboardingCompleted,
  saveIsOnboardingCompleted,
  loadClientPropertyDefault,
  saveClientPropertyDefault,
} from '../utils/client'
import { useLocation, Redirect } from 'react-router-dom'
import { IAssignedClient, IOrgUser } from '../service/interface'
import { useClientUser } from '../service/hooks/clientUser'
import { blacklistTokens } from '../service/api'
import {
  getAccessToken,
  getMaskAccessToken,
  getMaskRefreshToken,
  getRefreshToken,
  setRedirectUrl,
} from '../utils/auth'

interface IState {
  clientGuid: string
  clientName: string
  clientCreatedOn: string
  clientList: IAssignedClient[]
  isClientLoading: boolean
  clientRole: string
  isOnboardingCompleted: string
  clientUser?: IOrgUser
  clientPropertyDefault: string
  setClientCreatedOn: React.Dispatch<React.SetStateAction<string>>
  setClientGuid: React.Dispatch<React.SetStateAction<string>>
  setClientName: React.Dispatch<React.SetStateAction<string>>
  setClientRole: React.Dispatch<React.SetStateAction<string>>
  setIsOnboardingCompleted: React.Dispatch<React.SetStateAction<string>>
  setClientPropertyDefault: React.Dispatch<React.SetStateAction<string>>
}

const initialState: IState = {
  clientGuid: loadClientGuid(),
  clientName: loadClientName(),
  clientCreatedOn: loadClientCreatedOn(),
  clientRole: loadClientRole(),
  isOnboardingCompleted: loadIsOnboardingCompleted(),
  clientList: [],
  isClientLoading: false,
  clientPropertyDefault: loadClientPropertyDefault(),
  setClientRole: () => null,
  setClientCreatedOn: () => null,
  setClientGuid: () => null,
  setClientName: () => null,
  setIsOnboardingCompleted: () => null,
  setClientPropertyDefault: () => null,
}

export const ClientGuidContext = React.createContext<IState>(initialState)

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const ClientGuidContextProvider: React.FC<Props> = ({ children }) => {
  const [clientGuid, setClientGuid] = useState<string>(loadClientGuid())
  const [clientName, setClientName] = useState<string>(loadClientName())
  const [clientCreatedOn, setClientCreatedOn] = useState<string>(loadClientCreatedOn())
  const [clientRole, setClientRole] = useState<string>(loadClientRole())
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState<string>(
    loadIsOnboardingCompleted()
  )
  const [clientPropertyDefault, setClientPropertyDefault] = useState<string>(
    loadClientPropertyDefault()
  )
  const { data: clientUser } = useClientUser(clientGuid, 'me')
  const { data, isLoading, isError, error } = useListAssignedClients(1, {
    retry: (failureCount, error) => {
      // Only retry if the error wasn't caused by the terms not being accepted
      if (error.response && error.response.data.code === 'must_accept_terms') return false
      if (failureCount < 3) return true
      return false
    },
  })
  const clientList = useMemo(() => data?.results || [], [data])
  const location = useLocation()

  useLayoutEffect(() => {
    if (!clientGuid || !clientCreatedOn) {
      if (data && data.results[0] && data.count === 1) {
        // if user only belongs to 1 client
        const {
          guid,
          name,
          created_on: createdOn,
          role,
          is_onboarding_completed,
          has_properties,
        } = data?.results[0]
        setClientGuid(guid)
        saveClientGuid(guid)
        setClientName(name)
        saveClientName(name)
        setClientCreatedOn(createdOn)
        saveClientCreatedOn(createdOn)
        setClientRole(role || '')
        saveClientRole(role || '')
        setIsOnboardingCompleted(is_onboarding_completed ? '1' : '0')
        saveIsOnboardingCompleted(is_onboarding_completed ? '1' : '0')
        setClientPropertyDefault(has_properties ? '0' : '1')
        saveClientPropertyDefault(has_properties ? '0' : '1')
      }
    } else {
      if (data && data.results[0] && data.count === 1) {
        const { is_onboarding_completed, has_properties } = data?.results[0]
        setIsOnboardingCompleted(is_onboarding_completed ? '1' : '0')
        saveIsOnboardingCompleted(is_onboarding_completed ? '1' : '0')
        setClientPropertyDefault(has_properties ? '0' : '1')
        saveClientPropertyDefault(has_properties ? '0' : '1')
      }
    }
  }, [clientCreatedOn, clientGuid, data, location.pathname])

  if (
    isError &&
    error?.response.data.code === 'must_accept_terms' &&
    location.pathname !== '/terms-conditions'
  ) {
    setRedirectUrl((location.pathname || '/') + (location.search || ''))
    return (
      <Redirect to={{ pathname: '/terms-conditions', state: { referrer: location.pathname } }} />
    )
  }

  if (!clientGuid && data && data.count > 1 && location.pathname !== '/select-client') {
    setRedirectUrl((location.pathname || '/') + (location.search || ''))
    return <Redirect to={{ pathname: '/select-client', state: { referrer: location.pathname } }} />
  }

  if (data && data.count === 0) {
    blacklistTokens(getMaskRefreshToken(), getMaskAccessToken())
    blacklistTokens(getRefreshToken(), getAccessToken())
    window.localStorage.clear()
    return <Redirect to={{ pathname: '/login', search: '?error=no_client' }} />
  }

  if (isLoading && !isError) return <LoadingIndicator />

  if (
    isOnboardingCompleted === '0' &&
    clientGuid &&
    location.pathname !== '/client-onboarding/client'
  ) {
    setRedirectUrl((location.pathname || '/') + (location.search || ''))
    return (
      <Redirect
        to={{
          pathname: `/client-onboarding/client`,
          state: { referrer: location.pathname },
        }}
      />
    )
  }

  return (
    <ClientGuidContext.Provider
      value={{
        clientGuid,
        clientName,
        clientCreatedOn,
        clientList,
        isClientLoading: isLoading,
        clientRole,
        clientUser,
        isOnboardingCompleted,
        clientPropertyDefault,
        setClientCreatedOn,
        setClientGuid,
        setClientName,
        setClientRole,
        setIsOnboardingCompleted,
        setClientPropertyDefault,
      }}
    >
      {children}
    </ClientGuidContext.Provider>
  )
}
